<template>
    <div class="home">
        <img class="title" :src="titulo" alt="titulo">
        <div class="div-text">
            <span>
                <b style="color: white !important;">{{ $t('generic.modalLimitExceeded.title') }}</b>
            </span>
        </div>
        <img class="image" :src="iEnd" alt="iEnd">
        <div class="div-text2">
            <span>
                <b>{{ $t('generic.modalLimitExceeded.text') }}</b>                
            </span>
        </div>
        <div class="div-text3">
            <span>
                <b>{{ $t('generic.modalLimitExceeded.text1') }}</b><br/>
                <b>{{ $t('generic.modalLimitExceeded.text2') }}</b>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EndSessionView',
    data() {
        return {
            titulo: require('@/assets/images/Titulo.png'),
            iEnd: require('@/assets/images/EndView/FueraTiempo.png'),
        }
    },
}
</script>
<style scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    font-family: workSans-medium;
}
.title {
    width: 90%;
    margin-top: 30px;
}

.div-text {
    text-align: center;
    color: #aaa;
    font-size: 25px;
    width: 70%;
    margin-top: 60px;
}
.div-text2 {
    text-align: center;
    width: 80%;
    font-size: 24px;
    margin-top: 30px;
}
.div-text3 {
    text-align: center;
    width: 75%;
    color: orange;
    font-size: 18px;
    margin-top: 30px;
}
.image {
    width: 100px;
    height: 100px;
    margin-top: 30px;
}
@media (min-width: 390px) {
    .div-text {
        margin-top: 40px;
    }

    .div-text2 {
        margin-top: 40px;
    }
}
@media (min-width: 767px) {
    .div-text {
        font-size: 32px;
    }
    .div-text {
        font-size: 27px;
    }
}
@media (min-width: 1500px) {
    .title {
        width: 100%;
    }
    .div-text {
        font-size: 34px;
        margin-top: 100px;
    }
    .div-text2 {
        font-size: 29px;
        margin-top: 100px;
    }
}
</style>