<template>
  <MenuContent v-if="menuContentVisible" :isMenuContentVisible="true"></MenuContent>
  <div :class="'container' + styleModal">
    <div :class="'div-text' + styleModal">
      <h2 :class="'title' + styleModal">{{ textTitle }}</h2>
      <div>
        <span>
          <h4>{{ $t('components.legalWarning.paragraph1.title') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph1.text1') }}
            <br>
            {{ $t('components.legalWarning.paragraph1.listText1.item1') }}
            <br>
            {{ $t('components.legalWarning.paragraph1.listText1.item2') }}
            <br>
            {{ $t('components.legalWarning.paragraph1.listText1.item3') }}
            <br>
            {{ $t('components.legalWarning.paragraph1.listText1.item4') }}
            <br>
            {{ $t('components.legalWarning.paragraph1.listText1.item5') }}
            <br>
            {{ $t('components.legalWarning.paragraph1.listText1.item6') }}
          </p>
          <p>{{ $t('components.legalWarning.paragraph1.text2') }}
            <b>
              <a href='https://www.descubretuexperiencia.es' target="_blank"
                style="color: blue !important;">{{ $t('components.legalWarning.paragraph1.text2Link') }}</a>
            </b>
          </p>
          <p>{{ $t('components.legalWarning.paragraph1.text3') }}</p>
        </span>
      </div>
      <div>
        <span>
          <h4>{{ $t('components.legalWarning.paragraph2.title') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph2.text1') }}
            <b>
              <a href='https://www.tuspremiosencarnaval.es' target="_blank"
                style="color: blue !important;">{{ $t('components.legalWarning.paragraph1.text2Link') }}</a>
            </b> {{ $t('components.legalWarning.paragraph2.text1-1') }}
          </p>
          <p>{{ $t('components.legalWarning.paragraph2.text2') }}</p>
          <p>{{ $t('components.legalWarning.paragraph2.text3') }}</p>
          <p>{{ $t('components.legalWarning.paragraph2.text4') }}</p>
          <p>{{ $t('components.legalWarning.paragraph2.text5') }}</p>
          <p>{{ $t('components.legalWarning.paragraph2.text6') }}</p>
          <p>{{ $t('components.legalWarning.paragraph2.text7') }}</p>
          <p>{{ $t('components.legalWarning.paragraph2.text8') }}</p>
        </span>
      </div>
      <div>
        <span>
          <h4>{{ $t('components.legalWarning.paragraph3.title1') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph3.text1') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text2') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text3') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text4') }}</p>

          <h4>{{ $t('components.legalWarning.paragraph3.title2') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph3.text5') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text6') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text7') }}</p>

          <h4>{{ $t('components.legalWarning.paragraph3.title3') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph3.text8') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text9') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text10') }}</p>

          <h4>{{ $t('components.legalWarning.paragraph3.title4') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph3.text11') }}</p>

          <h4>{{ $t('components.legalWarning.paragraph3.title5') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph3.text13') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text14') }}</p>
          <p>{{ $t('components.legalWarning.paragraph3.text15') }}</p>

          <h4>{{ $t('components.legalWarning.paragraph3.title6') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph3.text16') }}</p>
        </span>
      </div>
      <div>
        <span>
          <h4>{{ $t('components.legalWarning.paragraph4.title') }}</h4>
          <p>{{ $t('components.legalWarning.paragraph4.text') }}</p>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import MenuContent from "@/components/MenuContent.vue";
export default {
  name: 'LegalWarning',
  props: {
    menuContentVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    styleModal: {
      type: String,
      required: false,
      default: ''
    },
    titleModal: {
      type: String,
      required: false,
      default: ''
    },
  },
  components: {
    MenuContent
  },
  data() {

    return {
      textTitle: this.titleModal != '' ? this.titleModal : this.$t('components.legalWarning.title')
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
  font-family: avenir;
}

.div-text {
  text-align: justify;
  width: 85%;
  color: rgb(0, 0, 0);
  font-size: 12px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-image: url('@/assets/images/Legal/CapaLegales.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  box-sizing: content-box;
}

.div-text b {
  color: white;
}

.title {
  text-align: center;
  font-family: spaceAge;
  font-size: 15px;
  color: white;
  padding: 5%;
  background-image: url('@/assets/images/Legal/TituloLegales.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

/* MODAL */
.container-modal {
  display: flex;
  height: auto;
  overflow-y: auto;
  font-family: avenir;
  width: 65vw;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.div-text-modal {
  display: flex;
  text-align: justify;
  color: rgb(0, 0, 0);
  font-size: 12px;
  height: auto;
  width: 65vw;
  flex-direction: column;
}

.div-text-modal b {
  color: white;
}

.title-modal {
  font-family: spaceAge;
  font-size: 20px;
  color: white;
  padding: 3%;
  background-image: url('@/assets/images/Legal/TituloLegales.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (min-width: 435px) {
  .container-modal {
    width: 55vw;
  }

  .div-text-modal {
    width: 55vw;
  }
}

@media (min-width: 450px) {
  .div-text {
    width: 70%;
  }
}

@media (min-width: 575px) {
  .container-modal {
    width: 45vw;
  }

  .div-text-modal {
    width: 45vw;
  }
}

@media (min-width: 700px) {
  .title {
    padding: 2.5%;
  }
}

@media (min-width: 685px) {
  .container-modal {
    width: 35vw;
  }

  .div-text-modal {
    width: 35vw;
  }
}

@media (min-width: 767px) {
  .div-text {
    width: 50%;
    font-size: 14px;
  }

  .title {
    font-size: 17px;
  }

  .div-text-modal {
    font-size: 14px;
  }

  .title-modal {
    font-size: 17px;
  }
}

@media (min-width: 960px) {
  .container-modal {
    width: 25vw;
  }

  .div-text-modal {
    width: 25vw;
  }
}

@media (min-width: 1000px) {
  .title {
    padding: 3%;
  }
}

@media (min-width: 1500px) {
  .div-text {
    font-size: 16px;
  }

  .title {
    font-size: 19px;
  }

  .div-text {
    width: 30%;
    padding: 2%;
  }

  .div-text-modal {
    font-size: 16px;
  }

  .title-modal {
    font-size: 19px;
  }
}

@media (min-width: 1696px) {
  .container-modal {
    width: 17vw;
  }

  .div-text-modal {
    width: 15vw;
  }
}

@media (min-width: 2000px) {
  .container-modal {
    width: 18vw;
  }

  .div-text-modal {
    width: 18vw;
  }
}

@media (min-width: 2500px) {
  .container-modal {
    width: 18vw;
  }

  .div-text-modal {
    width: 18vw;
  }
}
</style>