<template>
  <div class="container-modal">
    <div class="div-text-modal">
      <h2 class="title-modal">{{ $t('landingScan.modals.Questions.title') }}</h2>

      <div class="div-text">
        <span>
          <p>{{ $t('landingScan.modals.Questions.Question2.text1') }}
            <b style="color: black !important;">{{ $t('landingScan.modals.Questions.Question2.text2') }}</b>
            {{ $t('landingScan.modals.Questions.Question2.text3') }}
          </p>
        </span>
      </div>

      <div class="div-text2">
        <textarea v-model="textValue" autocomplete="off" type="text" id="description" placeholder="Escribe tu respuesta"
          rows="8" cols="30" @change="changeText"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { useInfoStore } from '@/stores/useInfoStore';

export default {
  // eslint-disable-next-line
  name: 'Question2',
  data() {
    const infoStore = useInfoStore();
    return {
      textValue: '',
      infoStore: infoStore
    };
  },
  methods: {
    changeText() {
      this.infoStore.setValue(this.textValue, 'secondAsk');
    }
  }
}
</script>

<style scoped>
/* MODAL */
.container-modal {
  display: flex;
  height: auto;
  /* overflow-y: auto; */
  font-family: avenir;
  width: 60vw;
  flex-direction: row;
  justify-content: center;
}

.div-text-modal {
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 12px;
  height: auto;
  width: 60vw;
  flex-direction: column;
  align-items: center;

  b {
    color: white;
  }
}

.title-modal {
  font-family: spaceAge;
  font-size: 25px;
  color: white;
  padding: 5%;
  background-image: url('@/assets/images/Legal/TituloLegales.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* display: inline-block; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px;
}

.div-text {
  font-size: 12px;
  margin-top: 5px;
  width: 80%;
}

.div-text2 {
  margin-top: 5px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 435px) {
  .container-modal {
    width: 50vw;
  }

  .div-text-modal {
    width: 50vw;
  }
}

@media (min-width: 575px) {
  .container-modal {
    width: 40vw;
  }

  .div-text-modal {
    width: 40vw;
  }
}

@media (min-width: 685px) {
  .container-modal {
    width: 30vw;
  }

  .div-text-modal {
    width: 30vw;
  }

}

@media (min-width: 767px) {

  .title-modal {
    font-size: 27px;
  }

  .div-text {
    font-size: 14px;
  }

  .slider-labels {
    font-size: 12px;
  }

}

@media (min-width: 960px) {
  .container-modal {
    width: 22vw;
  }

  .div-text-modal {
    width: 22vw;
  }

}

@media (min-width: 1500px) {
  .container-modal {
    width: 17vw;
  }

  .div-text-modal {
    width: 17vw;
  }

  .title-modal {
    font-size: 29px;
  }

  .div-text {
    font-size: 16px;
  }

  .slider-labels {
    font-size: 14px;
  }
}

@media (min-width: 2000px) {
  .container-modal {
    width: 12vw;
  }

  .div-text-modal {
    width: 12vw;
  }
}

@media (min-width: 3000px) {
  .container-modal {
    width: 8vw;
  }

  .div-text-modal {
    width: 8vw;
  }
}
</style>