<template>
  <main class="malboro app-background">
    <router-view />
  </main>
  <footer>
    <FooterContent></FooterContent>
  </footer>
</template>

<script>
import FooterContent from "@/components/FooterContent.vue";

export default {
  name: 'App',
  components: {
    FooterContent
  }
};
</script>

<style scoped>

</style>
