<template>
  <div class="container-modal">
    <div class="div-text-modal">
      <h2 class="title-modal">{{ $t('landingScan.modals.Questions.title') }}</h2>

      <div class="div-text">
        <span>
          <p>{{ $t('landingScan.modals.Questions.Question1') }} </p>
        </span>
      </div>

      <div class="div-text2">
        <div class="slider-labels">
          <span class="left-label"><b>{{ $t('landingScan.modals.Questions.sliderInfo.text1') }}</b></span>
          <span class="right-label"><b>{{ $t('landingScan.modals.Questions.sliderInfo.text2') }}</b></span>
        </div>
        <input class="custom-slider" v-model="sliderValue" type="range" min="0" max="10" step="1" @change="changeSlider">
        <div class="slider-marks">
          <div class="slider-tick">
            <span v-for="n in 11" :key="n"><b>| </b></span>
          </div>
          <div class="slider-tick2">
            <span v-for="n in 11" :key="n"><b>{{ n - 1 }}</b></span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { useInfoStore } from '@/stores/useInfoStore';

export default {
  // eslint-disable-next-line
  name: 'Question1',
  data() {
    const infoStore = useInfoStore();
    return {
      sliderValue: 5,
      infoStore: infoStore
    };
  },
  methods: {
    changeSlider() {
      this.infoStore.setValue(this.sliderValue.toString(), 'firstAsk');
    }
  }
}
</script>

<style scoped>
/* MODAL */
.container-modal {
  display: flex;
  height: auto;
  font-family: avenir;
  width: 60vw;
  flex-direction: row;
  justify-content: center;
}

.div-text-modal {
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 12px;
  height: auto;
  width: 60vw;
  flex-direction: column;
  align-items: center;
}
.title-modal {
  font-family: spaceAge;
  font-size: 25px;
  color: white;
  padding: 5%;
  background-image: url('@/assets/images/Legal/TituloLegales.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px;
}
.div-text {
  font-size: 12px;
  margin-top: 5px;
  width: 80%;
}
.div-text2 {
  margin-top: 5px;
  width: 90%;
}
.slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}
.slider-labels  span {
  width: 20%;
}
.slider-labels  b {
  color: black !important;
}
.slider-labels  .left-label {
  text-align: left;
}
.slider-labels  .right-label {
  text-align: right;
}
.custom-slider {
  width: 100%;
  height: 10px;
  border-radius: 0px;
  margin-top: 5px;
}
.slider-marks {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.slider-marks  b {
  color: black !important;
}
.slider-tick {
  display: flex;
  height: 10px;
  font-size: 10px;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  align-items: flex-end;
}

.slider-tick2 {
  display: flex;
  height: 10px;
  font-size: 10px;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  align-items: flex-end;
}

@media (min-width: 435px) {
  .container-modal {
    width: 50vw;
  }

  .div-text-modal {
    width: 50vw;
  }
}


@media (min-width: 490px) {
  .container-modal {
    width: 50vw;
  }

  .div-text-modal {
    width: 55vw;
  } 
}


@media (min-width: 575px) {
  .container-modal {
    width: 40vw;
  }

  .div-text-modal {
    width: 40vw;
  }
}

@media (min-width: 685px) {
  .container-modal {
    width: 30vw;
  }

  .div-text-modal {
    width: 30vw;
  }

}

@media (min-width: 767px) {

  .title-modal{
    font-size: 27px;
  }

  .div-text{
    font-size: 14px;
  }

  .slider-labels {
    font-size: 12px;
  }

}

@media (min-width: 800px) {
  .container-modal {
    width: 30vw;
  }
  .div-text-modal {
    width: 35vw;
  } 
}

@media (min-width: 960px) {
  .container-modal {
    width: 22vw;
  }

  .div-text-modal {
    width: 22vw;
  }

}

@media (min-width: 1111px) {
  .container-modal {
    width: 20vw;
  }

  .div-text-modal {
    width: 25vw;
  }
}

@media (min-width: 1500px) {
  .container-modal {
    width: 17vw;
  }

  .div-text-modal {
    width: 17vw;
  }

  .title-modal{
    font-size: 29px;
  }

  .div-text{
    font-size: 16px;
  }

  .slider-labels {
    font-size: 14px;
  }
}

@media (min-width: 2000px) {
  .container-modal {
    width: 12vw;
  }

  .div-text-modal {
    width: 12vw;
  }
}

@media (min-width: 3000px) {
  .container-modal {
    width: 8vw;
  }

  .div-text-modal {
    width: 8vw;
  }
}
</style>