<template>
    <div class="content-tt">
        <span class="text1">
            {{ $t('landingScan.text.text2') }}
        </span>
        <input v-model="ttCode" id="input-tt" type="text" :placeholder="$t('landingScan.placeholders.iptT&T')"
            :hidden="true" :disabled="true" />
        <button class="content-button" id="scan-tt" :hidden="buttonDisabled">
            <span class="span1" v-if="!btnIsScanEanText"> {{ btnScanEanText }} </span>
            <span v-if="btnIsScanEanText && !btnIsScanEanTextError"> {{ btnScanEanText }} </span>
            <span class="spanError" v-if="btnIsScanEanText && btnIsScanEanTextError"> {{ btnScanEanText }} </span>
        </button>
    </div>
    <div id="modal-tt" aria-modal="true" role="dialog">
        <div class="backdrop"></div>
        <div class="modal-inner">
            <div id="data-capture-view-tt"></div>
        </div>
    </div>
</template>

<script>
import { Camera, CameraSwitchControl, DataCaptureView, FrameSourceState, configure, loadingStatus, DataCaptureContext } from 'scandit-web-datacapture-core';
import { BarcodeCapture, BarcodeCaptureSettings, Symbology, barcodeCaptureLoader } from 'scandit-web-datacapture-barcode';
import { ref, onMounted } from 'vue';
import { useInfoStore } from '@/stores/useInfoStore';

export default {
    name: 'TtScan',
    computed: {
        btnIsScanEanText() {
            return this.contentCodeB != '' ? true : false;
        },
        btnIsScanEanTextError() {
            return this.contentCodeBError ? true : false;
        },
        btnScanEanText() {
            return this.contentCodeB != '' ? this.contentCodeB : this.$t('landingScan.placeholders.btnScanT&T');
        }
    },
    setup() {
        const showModal = ref(false);
        const barcode = ref('');
        const infoStore = useInfoStore();
        const buttonDisabled = ref(true);
        const ttCode = ref('');
        const contentCodeB = ref('');
        const contentCodeBError = ref(false);
        const pageElements = {
            input: null,
            button: null,
            modalContainer: null,
            modal: null,
            backdrop: null,
            captureHost: null,
        };
        let context;
        let barcodeCapture;
        const updateUIWithProgress = (info) => {
            if (info.percentage != null) {
                pageElements.input.value = `Loading... ${info.percentage}%`;
            }
            if (info.percentage === 100) {
                pageElements.input.value = '';
            }
        };
        const loadAndPrepareLibrary = async () => {
            loadingStatus.subscribe(updateUIWithProgress);
            await configure({
                licenseKey: process.env.VUE_APP_KEYLICENCE,
                libraryLocation: infoStore.libration,
                moduleLoaders: [barcodeCaptureLoader()],
            });
            loadingStatus.unsubscribe(updateUIWithProgress);
            context = await DataCaptureContext.create();
            await context.setFrameSource(Camera.default);

            const settings = new BarcodeCaptureSettings();
            settings.enableSymbologies([
                Symbology.EAN13UPCA,
                Symbology.EAN8,
                Symbology.DataMatrix,
                Symbology.DotCode
            ]);

            settings.settingsForSymbology(Symbology.DotCode).isColorInvertedEnabled = true;
            settings.settingsForSymbology(Symbology.DataMatrix).isColorInvertedEnabled = true;


            barcodeCapture = await BarcodeCapture.forContext(context, settings);
            await barcodeCapture.setEnabled(false);
            barcodeCapture.addListener({ didScan });
            const view = await DataCaptureView.forContext(context);
            view.connectToElement(pageElements.captureHost);
            view.addControl(new CameraSwitchControl());
        };
        const waitTransition = (element) =>
            new Promise((resolve) => {
                element.addEventListener(
                    'transitionend',
                    () => {
                        resolve();
                    },
                    { once: true }
                );
            });
        const closeModal = async () => {
            pageElements.modalContainer.classList.remove('open');
            await waitTransition(pageElements.modal);
            await context.frameSource.switchToDesiredState(FrameSourceState.Off);
        };
        const openModal = async () => {
            pageElements.modalContainer.classList.add('open');
            await waitTransition(pageElements.modal);
        };
        const onOpenModal = async () => {
            pageElements.input.blur();
            try {
                await context.frameSource.switchToDesiredState(FrameSourceState.On);
                await openModal();
                await barcodeCapture.setEnabled(true);
            } catch (error) {
                const reason = error.toString();
                alert(`Could not start camera: ${reason}`);
                pageElements.input.placeholder = reason;
                await closeModal();
            }
        };
        const didScan = async (barcodeCaptureMode, session) => {
            await barcodeCapture.setEnabled(false);
            const barcode = session.newlyRecognizedBarcodes[0];
            await closeModal();

            // console.log('informacion de barcode tt: ', barcode);

            //Comprobar validacion del tamaño o mostrar mensaje y comprobar tipo de codigo leido

            if (barcode.symbology === 'dataMatrix' || barcode.symbology === 'dotcode') {

                const codigoLimpio = barcode.data.replace(/[^\x20-\x7E]/g, '');
                const newCodeTT = codigoLimpio.slice(3, 18);

                infoStore.setValue(newCodeTT, 'ttCode');
                ttCode.value = newCodeTT;
                contentCodeB.value = newCodeTT;
                contentCodeBError.value = false;

            } else {
                infoStore.setValue('', 'ttCode');
                ttCode.value = "El tipo de código no es válido";
                contentCodeB.value = "El tipo de código no es válido";
                contentCodeBError.value = true;
            }
        };

        const handleOpenScanner = (event) => {
            event.preventDefault();
            onOpenModal();
        };

        onMounted(async () => {
            pageElements.input = document.getElementById('input-tt');
            pageElements.button = document.getElementById('scan-tt');
            pageElements.modalContainer = document.getElementById('modal-tt');
            pageElements.modal = document.querySelector('#modal-tt .modal-inner');
            pageElements.backdrop = document.querySelector('#modal-tt .backdrop');
            pageElements.captureHost = document.getElementById('data-capture-view-tt');

            await loadAndPrepareLibrary();

            buttonDisabled.value = false;
            pageElements.button.addEventListener('click', handleOpenScanner);
            pageElements.backdrop.addEventListener('click', closeModal);
            pageElements.input.addEventListener('focus', () => {
                pageElements.input.setSelectionRange(0, pageElements.input.value.length);
            });
            document.addEventListener('keydown', async (event) => {
                if (event.key === 'Escape') {
                    await closeModal();
                }
            });
        });

        return {
            showModal,
            barcode,
            buttonDisabled,
            ttCode,
            contentCodeB,
            contentCodeBError,
            openScanner: onOpenModal,
            closeScanner: closeModal,
        };
    },
}
</script>

<style scoped>
.content-tt {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.text1 {
    font-family: avenir;
    text-align: center;
    font-size: 10px;
    margin-bottom: 5px;
}

.content-button {
    padding: 23px;
    padding-left: 17%;
    padding-right: 1%;
    font-size: 20px;
    display: flex;
    color: #aaa;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    background-image: url('@/assets/images/LandingScanView/ImagenCSeguimiento.png');
    background-repeat: no-repeat;
    background-size: 45px auto;
    background-position: 0% center;
}

.content-button .span1 {
    font-size: 15px;
    color: #aaa;
}

.content-button .spanError {
    font-size: 15px;
    color: red;
}

#modal-tt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    pointer-events: none;
    z-index: 1003;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modal-tt .backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

#modal-tt .modal-inner {
    /* padding: 1%; */
    background-color: white;
    box-shadow: 3px 4px 12px 4px #919191;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
    transform: scale(0);
}

#modal-tt.open {
    pointer-events: auto;
}

#modal-tt.open .backdrop {
    opacity: 1;
}

#modal-tt.open .modal-inner {
    transform: scale(0.8);
}

#data-capture-view-tt {
    object-fit: cover;
    height: auto;
    width: auto;
}


@media (min-width: 341px) {
    .content-button .spanError {
        font-size: 17px;
    }
}

@media (min-width: 767px) {
    .text1 {
        font-size: 12px;
    }

    .content-button {
        font-size: 22px;
    }

    .content-button .span1 {
        font-size: 17px;
    }

    .content-button .spanError {
        font-size: 19px;
    }
}

@media (min-width: 1500px) {
    .text1 {
        font-size: 14px;
    }

    .content-button {
        font-size: 24px;
    }

    .content-button .span1 {
        font-size: 19px;
    }

    .content-button .spanError {
        font-size: 21px;
    }
}
</style>