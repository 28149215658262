<template>
    <div class="content-ean">
        <span class="text1">
            {{ $t('landingScan.text.text1') }}
        </span>
        <input v-model="eanCode" id="input-ean" type="text" :placeholder="$t('landingScan.placeholders.iptEan')"
            :hidden="true" :disabled="true" />
        <button class="content-button" id="scan-ean" :hidden="buttonDisabled">
            <span class="span1" v-if="!btnIsScanEanText"> {{ btnScanEanText }} </span>
            <span v-if="btnIsScanEanText && !btnIsScanEanTextError"> {{ btnScanEanText }} </span>
            <span class="spanError" v-if="btnIsScanEanText && btnIsScanEanTextError"> {{ btnScanEanText }} </span>
        </button>
    </div>
    <div id="modal-ean" aria-modal="true" role="dialog">
        <div class="backdrop"></div>
        <div class="modal-inner">
            <div id="data-capture-view-ean"></div>
        </div>
    </div>
</template>

<script>
import { Camera, CameraSwitchControl, DataCaptureView, FrameSourceState, configure, loadingStatus, DataCaptureContext } from 'scandit-web-datacapture-core';
import { BarcodeCapture, BarcodeCaptureSettings, Symbology, barcodeCaptureLoader } from 'scandit-web-datacapture-barcode';
import { ref, onMounted } from 'vue';
import { useInfoStore } from '@/stores/useInfoStore';

export default {
    name: 'EanScan',
    computed: {
        btnIsScanEanText() {
            return this.contentCodeB != '' ? true : false;
        },
        btnIsScanEanTextError() {
            return this.contentCodeBError ? true : false;
        },
        btnScanEanText() {
            return this.contentCodeB != '' ? this.contentCodeB : this.$t('landingScan.placeholders.btnScanEan');
        }
    },
    setup() {
        const showModal = ref(false);
        const barcode = ref('');
        const infoStore = useInfoStore();
        const buttonDisabled = ref(true);
        const eanCode = ref('');
        const contentCodeB = ref('');
        const contentCodeBError = ref(false);
        const pageElements = {
            input: null,
            button: null,
            modalContainer: null,
            modal: null,
            backdrop: null,
            captureHost: null,
        };
        let contextEan;
        let barcodeCaptureEan;
        const updateUIWithProgress = (info) => {
            if (info.percentage != null) {
                pageElements.input.value = `Loading... ${info.percentage}%`;
            }
            if (info.percentage === 100) {
                pageElements.input.value = '';
            }
        };
        const loadAndPrepareLibrary = async () => {
            loadingStatus.subscribe(updateUIWithProgress);
            await configure({
                licenseKey: process.env.VUE_APP_KEYLICENCE,
                libraryLocation: infoStore.libration,
                moduleLoaders: [barcodeCaptureLoader()],
            });
            loadingStatus.unsubscribe(updateUIWithProgress);
            contextEan = await DataCaptureContext.create();
            await contextEan.setFrameSource(Camera.default);

            const settingsEan = new BarcodeCaptureSettings();
            settingsEan.enableSymbologies([
                Symbology.EAN13UPCA,
                Symbology.EAN8,
                Symbology.DataMatrix,
                Symbology.DotCode
            ]);
            barcodeCaptureEan = await BarcodeCapture.forContext(contextEan, settingsEan);
            await barcodeCaptureEan.setEnabled(false);
            barcodeCaptureEan.addListener({ didScan });
            const viewEan = await DataCaptureView.forContext(contextEan);
            viewEan.connectToElement(pageElements.captureHost);
            viewEan.addControl(new CameraSwitchControl());
        };
        const waitTransition = (element) =>
            new Promise((resolve) => {
                element.addEventListener(
                    'transitionend',
                    () => {
                        resolve();
                    },
                    { once: true }
                );
            });
        const closeModal = async () => {
            pageElements.modalContainer.classList.remove('open');
            await waitTransition(pageElements.modal);
            await contextEan.frameSource.switchToDesiredState(FrameSourceState.Off);
        };
        const openModal = async () => {
            pageElements.modalContainer.classList.add('open');
            await waitTransition(pageElements.modal);
        };
        const onOpenModal = async () => {
            pageElements.input.blur();
            try {
                await contextEan.frameSource.switchToDesiredState(FrameSourceState.On);
                await openModal();
                await barcodeCaptureEan.setEnabled(true);
            } catch (error) {
                const reason = error.toString();
                alert(`Could not start camera: ${reason}`);
                pageElements.input.placeholder = reason;
                await closeModal();
            }
        };
        const didScan = async (barcodeCaptureMode, session) => {
            await barcodeCaptureEan.setEnabled(false);
            const barcode = session.newlyRecognizedBarcodes[0];
            await closeModal();
            // console.log('informacion de barcode ean: ', barcode);
            //Comprobar validacion del tamaño o mostrar mensaje y comprobar tipo de codigo leido
            if (barcode.symbology === 'ean8' || barcode.symbology === 'ean13Upca') {
                if (barcode.symbology === 'ean8') {
                    const eightDigitsRegex = /^\d{8}$/;
                    if (eightDigitsRegex.test(barcode.data)) {
                        if (infoStore.listEanCodes.includes(barcode.data)) {
                            infoStore.setValue(barcode.data, 'eanCode');
                            eanCode.value = barcode.data;
                            contentCodeB.value = barcode.data;
                            contentCodeBError.value = false;

                        } else {
                            infoStore.setValue('', 'eanCode');
                            eanCode.value = "El tipo de código no es válido";
                            contentCodeB.value = "El tipo de código no es válido";
                            contentCodeBError.value = true;
                        }
                    } else {
                        infoStore.setValue('', 'eanCode');
                        eanCode.value = "El tipo de código no es válido";
                        contentCodeB.value = "El tipo de código no es válido";
                        contentCodeBError.value = true;
                    }
                } else {
                    const thirteenDigitsRegex = /^\d{13}$/;
                    if (thirteenDigitsRegex.test(barcode.data)) {
                        if (infoStore.listEanCodes.includes(barcode.data)) {

                            infoStore.setValue(barcode.data, 'eanCode');
                            eanCode.value = barcode.data;
                            contentCodeB.value = barcode.data;
                            contentCodeBError.value = false;
                        } else {
                            infoStore.setValue('', 'eanCode');
                            eanCode.value = "El tipo de código no es válido";
                            contentCodeB.value = "El tipo de código no es válido";
                            contentCodeBError.value = true;
                        }
                    } else {
                        infoStore.setValue('', 'eanCode');
                        eanCode.value = "El tipo de código no es válido";
                        contentCodeB.value = "El tipo de código no es válido";
                        contentCodeBError.value = true;
                    }
                }
            } else {
                infoStore.setValue('', 'eanCode');
                eanCode.value = "El tipo de código no es válido";
                contentCodeB.value = "El tipo de código no es válido";
                contentCodeBError.value = true;
            }
        };
        const handleOpenScanner = (event) => {
            event.preventDefault();
            onOpenModal();
        };
        onMounted(async () => {
            pageElements.input = document.getElementById('input-ean');
            pageElements.button = document.getElementById('scan-ean');
            pageElements.modalContainer = document.getElementById('modal-ean');
            pageElements.modal = document.querySelector('#modal-ean .modal-inner');
            pageElements.backdrop = document.querySelector('#modal-ean .backdrop');
            pageElements.captureHost = document.getElementById('data-capture-view-ean');

            await loadAndPrepareLibrary();

            buttonDisabled.value = false;
            pageElements.button.addEventListener('click', handleOpenScanner);
            pageElements.backdrop.addEventListener('click', closeModal);
            pageElements.input.addEventListener('focus', () => {
                pageElements.input.setSelectionRange(0, pageElements.input.value.length);
            });
            document.addEventListener('keydown', async (event) => {
                if (event.key === 'Escape') {
                    await closeModal();
                }
            });
        });
        return {
            showModal,
            barcode,
            buttonDisabled,
            eanCode,
            contentCodeB,
            contentCodeBError,
            openScanner: onOpenModal,
            closeScanner: closeModal,
        };
    },
}
</script>

<style scoped>
.content-ean {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.text1 {
    font-family: avenir;
    text-align: center;
    font-size: 10px;
    margin-bottom: 5px;
}

.content-button {
    padding: 23px;
    padding-left: 17%;
    padding-right: 1%;
    font-size: 20px;
    display: flex;
    color: #aaa;
    align-items: center;
    flex-direction: row;
    gap: 5px;
    background-image: url('@/assets/images/LandingScanView/ImagenCBarras.png');
    background-repeat: no-repeat;
    background-size: 45px auto;
    background-position: 0% center;
}

.content-button .span1 {
    font-size: 15px;
}

.content-button .spanError {
    font-size: 15px;
    color: red;
}

#modal-ean {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    pointer-events: none;
    z-index: 1003;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modal-ean .backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

#modal-ean .modal-inner {
    /* padding: 1%; */
    background-color: white;
    box-shadow: 3px 4px 12px 4px #919191;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
    transform: scale(0);
}

#modal-ean.open {
    pointer-events: auto;
}

#modal-ean.open .backdrop {
    opacity: 1;
}

#modal-ean.open .modal-inner {
    transform: scale(0.8);
}

#data-capture-view-ean {
    object-fit: cover;
    height: auto;
    width: auto;
}


@media (min-width: 341px) {
    .content-button .spanError {
        font-size: 17px;
    }
}

@media (min-width: 767px) {
    .text1 {
        font-size: 12px;
    }

    .content-button {
        font-size: 22px;
    }

    .content-button .span1 {
        font-size: 17px;
    }

    .content-button .spanError {
        font-size: 19px;
    }
}

@media (min-width: 1500px) {
    .text1 {
        font-size: 14px;
    }

    .content-button {
        font-size: 24px;
    }

    .content-button .span1 {
        font-size: 19px;
    }

    .content-button .spanError {
        font-size: 21px;
    }
}
</style>