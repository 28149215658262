import router from "@/router";
import { defineStore } from "pinia";

export const useInfoStore = defineStore("useInfo", {
  state: () => ({
    libration:
      "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.25.0/build/engine/",

    //TODO: Variables guardadas durante la aplicación

    /* Variables para generar la sessión */
    sessionTimeOut: null,
    isSessionActive: false,

    /* Variable Url entrada */
    urlCode: localStorage.getItem("urlCode")
      ? localStorage.getItem("urlCode")
      : 0,

    /* Variable de AgeGate */
    month: 0,
    year: 0,

    /* Variables Landing-Access */
    mobile: "",

    //Respuesta de la peticion
    scanLimitExceeded: false,
    getStoreCode: false,
    getEmployeeCode: false,
    needTest: false,
    countParticipation: 0,

    /* Variables Landing-Scan */
    eanCode: "",
    ttCode: "",
    testComplee: false,
    testDataSend: false,
    firstAsk: "",
    secondAsk: "",
    thirdAsk: "",
    listEanCodes: [
      "87248258",
      "84160829",
      "87248432",
      "84160805",
      "8436609722104",
      "8437008795157",
      "84160812",
      "8436555618186",
    ],

    /* Variables Register | ConfirmPrize */
    token: "",
    prizeFinal: "",
  }),
  actions: {
    setValue(value, code) {
      switch (code) {
        /* TODO: Variable Url entrada */
        case "url":
          this.urlCode = value;
          localStorage.setItem("urlCode", value);
          break;

        /* TODO: Variable de AgeGate */
        case "month":
          this.month = value;
          break;
        case "year":
          this.year = value;
          break;

        /* TODO: Variables Landing-Access */
        case "mobile":
          this.mobile = value;
          break;
        case "scanLimitExceeded":
          this.scanLimitExceeded = value;
          break;
        case "getStoreCode":
          this.getStoreCode = value;
          break;
        case "getEmployeeCode":
          this.getEmployeeCode = value;
          break;
        case "needTest":
          this.needTest = value;
          break;
        case "countParticipation":
          this.countParticipation = value;
          break;

        /* TODO: Variables Landing-Scan */
        case "eanCode":
          this.eanCode = value;
          break;
        case "ttCode":
          this.ttCode = value;
          break;
        case "testComplee":
          this.testComplee = value;
          break;
        case "testDataSend":
          this.testDataSend = value;
          break;
        case "firstAsk":
          this.firstAsk = value;
          break;
        case "secondAsk":
          this.secondAsk = value;
          break;
        case "thirdAsk":
          this.thirdAsk = value;
          break;

        /* TODO: Variables Register | ConfirmPrize */
        case "token":
          this.token = value;
          break;
        case "prizeFinal":
          this.prizeFinal = value;
          break;

        default:
          console.warn("Unknown counter type: ", code);
          break;
      }
    },

    resetData() {
      (this.eanCode = ""), (this.ttCode = "");
    },

    startSessionTimer() {
      if (this.sessionTimeout != null) {
        clearTimeout(this.sessionTimeout);
        this.isSessionActive = false;
      }
      this.sessionTimeout = setTimeout(() => {
        router.push({
          name: "EndSession",
          params: {
            noReturn: true,
          },
        });
      }, 300000);
      this.isSessionActive = true;
    },

    clearSessionTimer() {
      if (this.sessionTimeout) {
        clearTimeout(this.sessionTimeout);
        this.sessionTimeout = null;
        this.isSessionActive = false;
      }
    },

    resetStore() {
      this.eanCode = "";
      this.ttCode = "";
    },
  },
});
