<template>
    <div class="home">
        <img class="title" :src="titulo" alt="titulo">
        <div class="div-text">
            <span>
                <b>{{ $t('confirmPrize.errorView.title') }}</b>
            </span>
        </div>
        <img class="image" :src="iSorry" alt="iSorry">
        <div class="div-text2">
            <span>
                <b>{{ $t('confirmPrize.errorView.text1') }}</b>
            </span>
        </div>
        <div class="div-text3">
            <span>
                <b>{{ $t('confirmPrize.errorView.text2') }}</b>
            </span>
        </div>
        <img @click="againParticipation" class="participation" :src="keepParticipating" alt="keepParticipating">
    </div>
</template>

<script>
export default {
    name: 'NoRegisterView',
    data() {
        return {
            titulo: require('@/assets/images/Titulo.png'),
            iSorry: require('@/assets/images/ScreensSorry/ImagenPerdon.png'),
            keepParticipating: require('@/assets/images/VolverParticipar.png')
        }
    },
    methods: {
        againParticipation() {
            this.$router.push({
                name: 'LandingScan'
            });
        }
    }
};
</script><style scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    font-family: workSans-medium;
}
.title {
    width: 90%;
    margin-top: 10px;
}

b {
    color: white;
}

.div-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70%;
    color: #aaa;
    font-size: 25px;
    margin-top: 20px;
}
.image {
    width: 100px;
    height: 100px;
    margin-top: 20px;
}
.div-text2 {
    text-align: center;
    width: 70%;
    color: #aaa;
    font-size: 25px;
    margin-top: 30px;
}
.div-text3 {
    text-align: center;
    width: 90%;
    color: #aaa;
    font-size: 25px;
    margin-top: 20px;
}
.div-text3 b {
    color: orange;
}

.participation {
    width: 250px;
    cursor: pointer;
    margin-top: 210px;
}

@media (min-width: 390px) {
    .div-text {
        margin-top: 20px;
    }
    .div-text2 {
        margin-top: 20px;
    }
    .div-text3 {
        margin-top: 20px;
    }
    .participation {
        margin-top: 180px;
        margin-bottom: 25px;
    }
}

@media (min-width: 767px) {
    .div-text {
        font-size: 37px;
    }
    .div-text2 {
        font-size: 37px;
    }
    .div-text3 {
        font-size: 37px;
    }    
    .participation {
        margin-top: 20px;
    }
}

@media (min-width: 1500px) {
    .title {
        width: 100%;
    }
    .div-text {
        margin-top: 50px;
        font-size: 39px;
    }
    .div-text2 {
        margin-top: 50px;
        font-size: 39px;
    }
    .div-text3 {
        margin-top: 50px;
        font-size: 39px;
    }
    .participation {
        margin-top: 50px;
    }
}
</style>