<template>
    <MenuContent></MenuContent>
    <div class="home">
        <img class="title" :src="titulo" alt="titulo">
        <div class="div-text">
            <span>
                <b>{{ $t('notWinnerTexts.title') }}</b>
            </span>
        </div>
        <img class="image" :src="iSorry" alt="iSorry">
        <div class="div-text2">
            <span>
                <b>{{ $t('notWinnerTexts.errors.text1') }}</b>
            </span>
        </div>
        <div class="div-text3">
            <span>
                <b>{{ $t('notWinnerTexts.errors.text2') }}</b><br />
                <b @click="handleTermsChange">{{ $t('notWinnerTexts.errors.text3') }}</b>
            </span>
        </div>
    </div>

    <!-- Modal de bases legales de promocion -->
    <div v-if="showTermsModal" class="modal">
        <div class="modal-content">
            <TermsConditions :titleModal="$t('generic.titleModal2.title1')" :menuContentVisible="false"
                styleModal="-modal">
            </TermsConditions>
            <div class="img-modal">
                <button class="btnCerrar" @click="closeModal('showTermsModal')">CERRAR</button>
            </div>
        </div>
    </div>
</template>

<script>
import TermsConditions from "@/components/TermsConditions.vue";
import MenuContent from "@/components/MenuContent.vue";
export default {
    name: 'ErrorsView',
    components: {
        MenuContent,
        TermsConditions,
    },
    data() {
        return {
            titulo: require('@/assets/images/Titulo.png'),
            iSorry: require('@/assets/images/ScreensSorry/ImagenPerdon.png'),
            keepParticipating: require('@/assets/images/VolverParticipar.png'),
            showTermsModal: false,
        }
    },
    mounted() {
        if (localStorage.getItem('canLoad') != "0"){
            this.$router.push({
                name: 'LandingAccess'
            });
        }
    },
    beforeUnmount(){
        localStorage.removeItem('canLoad');
    },
    methods: {
        againParticipation() {
            this.$router.push({
                name: 'LandingScan'
            });
        },
        handleTermsChange() {
            this.showTermsModal = true;
        },
        closeModal(modal) {
            this[modal] = false;
        },
    }
};
</script>
<style scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    font-family: workSans-medium;
}

.title {
    width: 90%;
    margin-top: 10px;
}

b {
    color: white;
}

.div-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70%;
    color: #aaa;
    font-size: 25px;
    margin-top: 60px;
}

.image {
    width: 100px;
    height: 100px;
    margin-top: 20px;
}

.div-text2 {
    text-align: center;
    width: 75%;
    color: #aaa;
    font-size: 25px;
    margin-top: 30px;
}

.div-text3 {
    text-align: center;
    width: 90%;
    color: #aaa;
    font-size: 18px;
    margin-top: 30px;
}

.div-text3 b {
    color: orange;
}

.participation {
    width: 250px;
    cursor: pointer;
    margin-top: 10px;
}

/* Estilos para el modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    z-index: 1002;
}

.modal-content {
    display: flex;
    width: 100vw;
    height: 75vh;
    overflow-y: auto;
    padding: 10%;
    background-image: url('@/assets/images/Legal/CapaLegales.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-sizing: content-box;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: relative;
    margin-bottom: 80px;
}

.img-modal {
    display: flex;
    width: 55vw;
    align-items: center;
    justify-content: center;
}

.img-modal button {
    width: 90px;
    margin: 5px;
    padding: 5px;
    font-family: spaceAge;
    border: 0.1px solid grey;
}

.btnCerrar {
    background-color: #aaa;
    color: black;
}

@media (min-width: 390px) {
    .div-text {
        margin-top: 20px;
    }

    .div-text2 {
        margin-top: 10px;
    }

    .div-text3 {
        margin-top: 10px;
    }

    .participation {
        margin-top: 20px;
    }
}

@media (min-width: 435px) {
    .img-modal {
        width: 45vw;
    }
}

@media (min-width: 575px) {
    .img-modal {
        width: 35vw;
    }

    .modal-content {
        padding: 7%;
    }
}

@media (min-width: 630px) {
  .modal-content {
    margin-bottom: 0px;
  }
}

@media (min-width: 685px) {
    .img-modal {
        width: 25vw;
    }
}

@media (min-width: 767px) {
    .div-text {
        font-size: 37px;
    }

    .div-text2 {
        font-size: 37px;
    }

    .div-text3 {
        font-size: 37px;
    }
}

@media (min-width: 900px) {
    .modal-content {
        padding: 6%;
    }
}

@media (min-width: 960px) {
    .img-modal {
        width: 20vw;
    }
}

@media (min-width: 1500px) {
    .title {
        width: 100%;
    }

    .div-text {
        margin-top: 50px;
        font-size: 39px;
    }

    .div-text2 {
        margin-top: 50px;
        font-size: 39px;
    }

    .div-text3 {
        margin-top: 50px;
        font-size: 39px;
    }

    .participation {
        margin-top: 50px;
    }

    .modal-content {
        padding: 4%;
    }

    .img-modal {
        width: 15vw;
    }
}

@media (min-width: 2000px) {
    .img-modal {
        width: 10vw;
    }

    .modal-content {
        padding: 2%;
    }

}

@media (min-width: 3000px) {
    .img-modal {
        width: 7vw;
    }
}
</style>