<template>
    <div class="home">
        <img class="title" :src="titulo" alt="titulo">

        <div class="div-text">
            <p>
                {{ $t('home.endView.text1') }}
                <br>
                <span>{{ $t('home.endView.text2') }}</span>
                <br>
                {{ $t('home.endView.text3') }}
            </p>
        </div>
        <img class="image" :src="iEnd" alt="iEnd">
    </div>
</template>

<script>

export default {
    name: 'EndView',
    data() {
        return {
            titulo: require('@/assets/images/Titulo.png'),
            iEnd: require('@/assets/images/EndView/FueraTiempo.png'),
        }
    },
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
}

.title {
    width: 90%;
    margin-top: 30px;
}

.div-text {
    text-align: center;
    font-size: 30px;
    width: 70%;
    margin-top: 30px;
    color: white;
    font-size: 22px;
    font-weight: bolder;
}
.div-text span{
    color: orange;
}
.image {
    width: 100px;
    height: 100px;
    margin-top: 30px;
}

@media (min-width: 390px) {
    .div-text{
        margin-top: 70px;
    }

    .image{
        margin-top: 70px;
    }

}

@media (min-width: 767px) {
    .div-text {
        font-size: 32px;
    }
}

@media (min-width: 1500px) {
    .title {
        width: 100%;
    }

    .div-text{
        font-size: 34px;
        margin-top: 140px;
    }

    .image{
        margin-top: 100px;
    }
}
</style>