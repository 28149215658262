import { createRouter, createWebHistory } from "vue-router";
import { useInfoStore } from "@/stores/useInfoStore";
import { useRequestStore } from "@/stores/useRequestsStore";
import AgeGateView from "../views/AgeGate/AgeGateView.vue";
import LandingAccessView from "../views/LandingAccess/LandingAccessView.vue";
import LandingScanView from "../views/LandingScan/LandingScanView.vue";
import LegalWarning from "@/components/LegalWarning.vue";
import TermsConditions from "@/components/TermsConditions.vue";
import FaQ from "@/views/LandingScan/componentsMenu/Faq.vue";
import Contact from "@/views/LandingScan/componentsMenu/Contact.vue";
import Register from "@/views/AfterSms/Register.vue";
import CounterView from "@/views/Home/CounterView.vue";
import EndView from "@/views/Home/EndView.vue";
import NoLuckView from "@/views/Sorry/NoLuckView.vue";
import NoMoreForTodayView from "@/views/Sorry/NoMoreForTodayView.vue";
import MaximumExceededView from "@/views/Sorry/MaximumExceededView.vue";
import ErrorsView from "@/views/Sorry/ErrorsView.vue";
import CodeUsedView from "@/views/Sorry/CodeUsedView.vue";
import JackpotRegView from "@/views/AfterSms/PrizeView/JackpotRegView.vue";
import JackpotNoRegView from "@/views/AfterSms/PrizeView/JackpotNoRegView.vue";
import PrizeFiveHundredRegView from "@/views/AfterSms/PrizeView/PrizeFiveHundredRegView.vue";
import PrizeFiveHundredNoRegView from "@/views/AfterSms/PrizeView/PrizeFiveHundredNoRegView.vue";
import PrizeRallyView from "@/views/Winner/PrizeRallyView.vue";
import PrizeBikeView from "@/views/Winner/PrizeBikeView.vue";
import PrizeCatamaranView from "@/views/Winner/PrizeCatamaranView.vue";
import PrizeAladiniaView from "@/views/Winner/PrizeAladiniaView.vue";
import EndSessionView from "@/views/EndSession/EndSessionView.vue";
import NoRegisterView from "@/views/AfterSms/PrizeView/ErrorView/NoRegisterView.vue";

const routes = [
  //Pantallas Inicio
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/ageGate",
    name: "AgeGate",
    component: AgeGateView,
  },
  {
    path: "/end",
    name: "EndView",
    component: EndView,
    meta: { hideFooter: true },
  },
  {
    path: "/counter",
    name: "CounterView",
    component: CounterView,
  },

  //Resto pantallas aplicacion
  {
    path: "/catamaran",
    name: "HomeShipView",
    component: AgeGateView,
  },
  {
    path: "/moto",
    name: "HomeMotorBikeView",
    component: AgeGateView,
  },
  {
    path: "/rally",
    name: "HomeCarView",
    component: AgeGateView,
  },
  {
    path: "/landing-access",
    name: "LandingAccess",
    component: LandingAccessView,
  },
  {
    path: "/landing-scan",
    name: "LandingScan",
    component: LandingScanView,
    meta: { hideFooter: true },
  },
  {
    path: "/legal",
    name: "LegalWarning",
    component: LegalWarning,
    meta: { hideFooter: true },
  },
  {
    path: "/terms_Conditions",
    name: "TermsConditions",
    component: TermsConditions,
    meta: { hideFooter: true },
  },
  {
    path: "/faq",
    name: "FaQ",
    component: FaQ,
    meta: { hideFooter: true },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { hideFooter: true },
  },
  {
    path: "/cantRegister",
    name: "CantRegister",
    component: NoRegisterView,
    meta: { hideFooter: true },
  },

  //Pantalla de fin sesión
  {
    path: "/endSession",
    name: "EndSession",
    component: EndSessionView,
    meta: { hideFooter: true },
  },

  //Pantallas premios tras verificar códigos
  {
    path: "/winner",
    name: "Aladinia",
    component: PrizeAladiniaView,
    meta: { hideFooter: true },
  },
  {
    path: "/winner",
    name: "Rally",
    component: PrizeRallyView,
    meta: { hideFooter: true },
  },
  {
    path: "/winner",
    name: "Moto",
    component: PrizeBikeView,
    meta: { hideFooter: true },
  },
  {
    path: "/winner",
    name: "Catamarán",
    component: PrizeCatamaranView,
    meta: { hideFooter: true },
  },

  //Pantallas lo sentimos
  {
    path: "/sorry",
    name: "NoLuck",
    component: NoLuckView,
    meta: { hideFooter: true },
  },
  {
    path: "/sorry",
    name: "NoMoreForToday",
    component: NoMoreForTodayView,
    meta: { hideFooter: true },
  },
  {
    path: "/sorry",
    name: "CodeUsed",
    component: CodeUsedView,
    meta: { hideFooter: true },
  },
  {
    path: "/sorry",
    name: "MaximumExceeded",
    component: MaximumExceededView,
    meta: { hideFooter: true },
  },
  {
    path: "/sorry",
    name: "20Errors",
    component: ErrorsView,
    meta: { hideFooter: true },
  },

  //Pantallas premios tras SMS
  {
    path: "/prize",
    name: "JackpotReg",
    component: JackpotRegView,
    meta: { hideFooter: true },
  },
  {
    path: "/prize",
    name: "JackpotNoReg",
    component: JackpotNoRegView,
    meta: { hideFooter: true },
  },
  {
    path: "/prize",
    name: "PrizeFiveHundredReg",
    component: PrizeFiveHundredRegView,
    meta: { hideFooter: true },
  },
  {
    path: "/prize",
    name: "PrizeFiveHundredNoReg",
    component: PrizeFiveHundredNoRegView,
    meta: { hideFooter: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//Pantalla Home segun estado aplicación
router.beforeEach(async (to, from, next) => {
  switch (to.name) {
    case "Home":
    case "HomeCarView":
    case "HomeMotorBikeView":
    case "HomeShipView":
      try {
        const requestStore = useRequestStore();
        const stateResponse = await requestStore.getStateAplication();
        var state = stateResponse.campaign_status;
        // var state = "0";

        switch (state) {
          case "0":
            localStorage.setItem("state", state);
            next({ name: "CounterView" });
            break;
          case "1":
            localStorage.setItem("state", state);
            next();
            break;
          case "3":
            localStorage.setItem("state", state);
            next({ name: "EndView" });
            break;
          default:
            break;
        }
      } catch (error) {
        throw new Error(
          "La peticion del estado inicial de la aplicación ha fallado"
        );
      }
      break;
    default:
      next();
      break;
  }
});

//Se guarda el codigo de entrada (moto, coche, catamaran)
router.beforeEach((to, from, next) => {
  const infoStore = useInfoStore();
  switch (to.name) {
    case "Home":
      infoStore.setValue(0, "url");
      next({ name: "AgeGate" });
      break;
    case "HomeCarView":
      infoStore.setValue(1, "url");
      next({ name: "AgeGate" });
      break;
    case "HomeMotorBikeView":
      infoStore.setValue(2, "url");
      next({ name: "AgeGate" });
      break;
    case "HomeShipView":
      infoStore.setValue(3, "url");
      next({ name: "AgeGate" });
      break;
    default:
      next();
      break;
  }
});

//Se comprueba si se esta accediendo a AgeGate o Register para iniciar la sesion o resetearla
router.beforeEach((to, from, next) => {
  const infoStore = useInfoStore();
  if (
    to.name === "AgeGate" ||
    (to.name === "Register" && to.query.token != undefined)
  ) {
    infoStore.startSessionTimer();
    next();
  } else {
    next();
  }
});

//Pantalla de no mas intentos por hoy, pero sin la opcion de volver atras o acceder a otra pagina
router.beforeEach((to, from, next) => {
  const allowedRoutes = ["LegalWarning", "TermsConditions", "FaQ", "Contact"];

  if (allowedRoutes.includes(to.name)) {
    next();
    return;
  }

  if (to.name === "NoMoreForToday" && from.params.noReturn) {
    window.history.pushState(null, document.title, window.location.href);
    next();
  } else if (from.name === "NoMoreForToday") {
    next(false);
  } else {
    next();
  }
});


//Pantalla de maximo excedido y 20 errores, pero sin la opcion de volver atras o acceder a otra pagina
router.beforeEach((to, from, next) => {
  const allowedRoutes = ["LegalWarning", "TermsConditions", "FaQ", "Contact"];

  if (allowedRoutes.includes(to.name)) {
    next();
    return;
  }

  if (
    (to.name === "MaximumExceeded" && from.params.noReturn) ||
    (to.name === "20Errors" && from.params.noReturn)
  ) {
    window.history.pushState(null, document.title, window.location.href);
    next();
  } else if (from.name === "MaximumExceeded" || from.name === "20Errors") {
    next(false);
  } else {
    next();
  }
});

//Se incorpora una funcion que revise el caso en el que se llame a /register o /confirmPrize
//y en dicho caso obtener el parametro adicional que se manda y guardarlo en el store para emplearlo
//posteriormente en una peticion. ES POSIBLE QUE SIEMPRE SE LLAME A REGISTER
router.beforeEach(async (to, from, next) => {
  const infoStore = useInfoStore();
  const requestStore = useRequestStore();

  if (to.name === "Register" || to.name === "ConfirmPrize") {
    const token = to.query.token;

    if (!token) {
      next({ name: "AgeGate" });
      return;
    }

    infoStore.setValue(token, "token");

    const result = await requestStore.getParameterData(token);
    if (result.status === "success" && !result.data.expired) {
      infoStore.setValue(result.data.mobile, "mobile");
      infoStore.setValue(result.data.urlCode, "url");

      const [, month, year] = result.data.date.split("/");

      infoStore.setValue(parseInt(month), "month");
      infoStore.setValue(parseInt(year), "year");

      infoStore.setValue(!result.data.getStoreCode, "getStoreCode");
      infoStore.setValue(!result.data.getEmployeeCode, "getEmployeeCode");

      infoStore.setValue(result.data.countParticipation, 'countParticipation');

      if (!result.data.register) {
        infoStore.setValue(result.data.prize, "prizeFinal");
        next();
      } else {
        localStorage.setItem('canLoad', '3');
        switch (result.data.prize) {
          case "rally":
          case "moto":
          case "catamarán":
            next({ name: "JackpotNoReg" });
            break;
          default:
            next({ name: "PrizeFiveHundredNoReg" });
            break;
        }
      }
    } else if (result.status === "success" && result.data.expired) {
      infoStore.setValue(result.data.mobile, "mobile");
      infoStore.setValue(result.data.urlCode, "url");

      const [, month, year] = result.data.date.split("/");

      infoStore.setValue(parseInt(month), "month");
      infoStore.setValue(parseInt(year), "year");

      infoStore.setValue(!result.data.getStoreCode, "getStoreCode");
      infoStore.setValue(!result.data.getEmployeeCode, "getEmployeeCode");

      infoStore.setValue(result.data.countParticipation, 'countParticipation');

      next({ name: "CantRegister" });
      return;
    } else{
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const infoStore = useInfoStore();
  const requestStore = useRequestStore();

  const sessionInitRoutes = ["EndView", "CounterView", "AgeGate", "Register"];
  const sessionInitRoutesAux = ["AgeGate", "Register"];
  const sessionInitRoutesEnd = ["EndView"];
  const sessionInitRoutesCounter = ["CounterView"];
  const isSessionActiveBolean = infoStore.isSessionActive;

  let state;

  if (localStorage.getItem("state")) {
    state = localStorage.getItem("state");
  } else if (localStorage.getItem("state") === null) {
    const stateResponse = await requestStore.getStateAplication();
    state = stateResponse.campaign_status;
    localStorage.setItem("state", state);
  }

  if (!isSessionActiveBolean && !sessionInitRoutes.includes(to.name)) {
    if (state === "3") {
      next({ name: "EndView" });
      return;
    } else if (state === "0") {
      next({ name: "CounterView" });
      return;
    } else if (state === "1") {
      next({ name: "AgeGate" });
      return;
    }
  } else if (
    !isSessionActiveBolean &&
    sessionInitRoutesCounter.includes(to.name)
  ) {
    if (state === "0") {
      next();
      return;
    } else if (state === "3") {
      next({ name: "EndView" });
      return;
    } else if (state === "1") {
      next({ name: "AgeGate" });
      return;
    }
  } else if (!isSessionActiveBolean && sessionInitRoutesEnd.includes(to.name)) {
    if (state === "3") {
      next();
      return;
    } else if (state === "0") {
      next({ name: "CounterView" });
      return;
    } else if (state === "1") {
      next({ name: "AgeGate" });
      return;
    }
  } else if (isSessionActiveBolean && sessionInitRoutesAux.includes(to.name)) {
    if (state === "1") {
      next();
      return;
    } else if (state === "0") {
      next({ name: "CounterView" });
      return;
    } else if (state === "3") {
      next({ name: "EndView" });
      return;
    }
  }
  next();
});

//Sesión acabada. Sin la opcion de volver atras o acceder a otra pagina
router.beforeEach((to, from, next) => {
  if (to.name === "EndSession" && from.params.noReturn) {
    window.history.pushState(null, document.title, window.location.href);
    next();
  } else if (from.name === "EndSession") {
    next(false);
  } else {
    next();
  }
});

export default router;
