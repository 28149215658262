<template>
  <MenuContent></MenuContent>
  <div class="home">

    <img class="title" :src="titulo" alt="titulo">

    <div class="div-text">
      <span>
        <b>{{ $t('register.title') }}</b>
      </span>
    </div>

    <form ref="myForm" class="content" @submit.prevent="validate">
      <div class="form-inputs">
        <input autocomplete="off" v-model="name" type="text" :placeholder="$t('register.name')" minlength="3" required>
        <input autocomplete="off" v-model="lastName" type="text" :placeholder="$t('register.lastName')" minlength="3"
          required>
        <input autocomplete="off" v-model="postalCode" type="number" :placeholder="$t('register.postalCode')" required>
        <input autocomplete="off" v-model="email" type="email" :placeholder="$t('register.email')" required>
        <input autocomplete="off" v-model="reEmail" type="email" :placeholder="$t('register.rep-email')" required>
      </div>

      <div class="terms">
        <div class="terms-div" @click="handleTermsChange">
          <input class="terms-input" type="checkbox" v-model="termsAccepted" id="terms" required />
          <span>
            {{ $t('generic.termsCheck.text1') }}
            <span class="legalText">{{ $t('generic.termsCheck.text2') }}</span>
            {{ $t('generic.termsCheck.text3') }}
          </span>
        </div>

        <div class="terms-div">
          <input class="terms-input" type="checkbox" v-model="policyAccepted" id="policy" required>
          <span>
            {{ $t('generic.policyText.text1') }}
            <a href="https://www.pmiprivacy.com/spain/es/consumer/" target="_blank">{{ $t('generic.policyText.text2')
              }}</a>
            {{ $t('generic.policyText.text3') }}
            <span class="legalText" @click="openUsageTermsModal">{{ $t('generic.policyText.text4') }}</span>
            {{ $t('generic.policyText.text5') }}
          </span>
        </div>
      </div>
    </form>

    <img @click.prevent="Submit" class="send" :class="{ disabled: isSubmitDisabled }" :src="send" alt="send">
  </div>

  <!-- Modal de bases legales de promocion -->
  <div v-if="showTermsModal" class="modal">
    <div class="modal-content">
      <TermsConditions :titleModal="$t('generic.titleModal2.title1')" :menuContentVisible="false" styleModal="-modal"
        styleModalButtons="modalButtons" @scrolled-to-bottom="handleScrollToBottom">
      </TermsConditions>
      <div v-if="buttonsVisible" class="img-modal">
        <button class="btnAceptar" @click="closeModalOK('showTermsModal')">ACEPTAR</button>
        <button class="btnCerrar" @click="closeModalKO('showTermsModal')">CERRAR</button>
      </div>
    </div>
  </div>

  <!-- Modal de términos de uso -->
  <div v-if="showUsageTermsModal" class="modal">
    <div class="modal-content">
      <LegalWarning :titleModal="$t('generic.titleModal2.title2')" :menuContentVisible="false" styleModal="-modal">
      </LegalWarning>
      <div class="img-modal">
        <button class="btnAceptar" @click="closeModalOK('showUsageTermsModal')">ACEPTAR</button>
        <button class="btnCerrar" @click="closeModalKO('showUsageTermsModal')">CERRAR</button>
      </div>
    </div>
  </div>

  <!-- Modal errors -->
  <div v-if="showError" class="modal">
    <div class="modal-content-final">
      <ModalWarning :textModal="textError"></ModalWarning>
      <div class="img-modal">
        <button class="btnAceptar" @click="closeModal('showError')">CERRAR</button>
      </div>
    </div>
  </div>

</template>

<script>
import { useInfoStore } from '@/stores/useInfoStore';
import { useRequestStore } from "@/stores/useRequestsStore";
import TermsConditions from "@/components/TermsConditions.vue"
import LegalWarning from "@/components/LegalWarning.vue"
import MenuContent from "@/components/MenuContent.vue";
import ModalWarning from "@/components/Modals/ModalWarning.vue";

export default {
  // eslint-disable-next-line
  name: 'Register',
  components: {
    TermsConditions,
    LegalWarning,
    MenuContent,
    ModalWarning
  },
  data() {
    const infoStore = useInfoStore();
    const requestStore = useRequestStore();

    return {
      titulo: require('@/assets/images/Titulo.png'),
      send: require('@/assets/images/Enviar.png'),
      infoStore: infoStore,
      requestStore: requestStore,
      name: '',
      lastName: '',
      postalCode: '',
      email: '',
      reEmail: '',
      termsAccepted: false,
      policyAccepted: false,
      showTermsModal: false,
      showUsageTermsModal: false,
      showError: false,
      textError: '',
      buttonsVisible: false,
      isSubmitDisabled: false,
    }
  },
  mounted() {
    this.preloadImage(require('@/assets/images/Modals/BaseEncuestaUltima.png'));
    this.preloadImage(require('@/assets/images/Legal/CapaLegales.png'));
  },
  methods: {
    preloadImage(imagePath) {
      const img = new Image();
      img.src = imagePath;
    },
    Submit() {
      if (this.isSubmitDisabled) return;

      const form = this.$refs.myForm;
      const event = new Event('submit', { cancelable: true });
      form.addEventListener('submit', (e) => e.preventDefault());
      form.dispatchEvent(event);  // Despacha el evento submit
    },

    async validate() {
      const form = this.$refs.myForm;
      if (form.checkValidity()) {

        if (this.email != this.reEmail) {
          this.showError = true;
          this.textError = this.$t('register.modalErrors.text1');
          return;
        }

        this.isSubmitDisabled = true;

        const data = {
          name: this.name,
          lastName: this.lastName,
          postalCode: this.postalCode,
          email: this.email,
          mobile: this.infoStore.mobile,
          urlParameter: this.infoStore.token
        }

        const result = await this.requestStore.postRegister(data);

        if (result.status === "success") {

          const dataConfirm = {
            urlParameter: this.infoStore.token,
            mobile: this.infoStore.mobile
          }

          const resultConfirm = await this.requestStore.postConfirmPrize(dataConfirm);

          if (resultConfirm.status === "success") {
            this.isSubmitDisabled = false;
            localStorage.setItem('canLoad', '2');
            switch (this.infoStore.prizeFinal) {
              case "rally":
                this.$router.push({
                  name: 'JackpotReg',
                });
                break;
              case "moto":
                this.$router.push({
                  name: 'JackpotReg',
                });
                break;
              case "catamarán":
                this.$router.push({
                  name: 'JackpotReg',
                });
                break;
              //caso aladinia
              case "bono":
                this.$router.push({
                  name: 'PrizeFiveHundredReg',
                });
                break;
              default:
                break;
            }
          } else {
            this.showError = true;
            this.textError = "Se ha producido un problema durante la confirmación del premio";
            this.isSubmitDisabled = false;
            return;
          }

        } else {
          const errorTextResult = this.getErrorsArray(result);
          this.showError = true;
          this.textError = errorTextResult;
          this.isSubmitDisabled = false;
          return;
        }
      } else {
        form.reportValidity(); // Mostrar los errores nativos de validación si los hay
      }
    },

    getErrorsArray(response) {
      if (Array.isArray(response.data)) {

        const mensajes = response.data.map(obj => Object.values(obj)).flat();

        return mensajes.join('\n');
      }
      return '';
    },

    handleTermsChange() {
      this.showTermsModal = true;
      this.buttonsVisible = false;
    },

    openUsageTermsModal() {
      this.showUsageTermsModal = true;
    },

    closeModal(modal) {
      this[modal] = false;
    },
    closeModalOK(modal) {
      if (modal == 'showTermsModal') { this.termsAccepted = true }
      else { this.policyAccepted = true }
      this.closeModal(modal);
    },
    closeModalKO(modal) {
      if (modal == 'showTermsModal') { this.termsAccepted = false }
      else { this.policyAccepted = false }
      this.closeModal(modal);
    },
    handleScrollToBottom() {
      this.buttonsVisible = true;
    }
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
}

.title {
  width: 90%;
  margin-top: 10px;
}

.div-text {
  font-family: workSans-medium;
  text-align: center;
  width: 70%;
  color: #aaa;
  font-size: 30px;
  margin-top: 25px;
}

.div-text b {
  color: white;
}

.content {
  display: flex;
  flex-flow: column;
  font-family: avenir;
  width: 95%;
  margin-top: 20px;
  align-items: center;
}

.form-inputs {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  width: 80%;
}

.form-inputs input {
  padding: 10px 5px;
  font-family: 'avenir';
  font-size: 13px;
}

.form-inputs input::placeholder {
  color: #aaa;
}

.terms {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-top: 25px;
}

.terms-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  column-gap: 10px;
}

.terms-div .terms-input {
  transform: scale(1.4);
}

.terms-div span {
  font-size: 14.5px;
  color: #aaa;
}

.terms-div span .legalText {
  cursor: pointer;
  color: white;
}

.terms-div a {
  color: white !important;
  text-decoration: none;
}

.send {
  width: 250px;
  cursor: pointer;
  margin-top: 30px;
}

.send.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Modals */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1002;
}

.modal-content {
  display: flex;
  width: 100vw;
  height: 75vh;
  overflow-y: auto;
  padding: 10%;
  background-image: url('@/assets/images/Legal/CapaLegales.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: relative;
  margin-bottom: 80px;
}

.modal-content-final {
  font-family: spaceAge;
  display: flex;
  width: 100vw;
  height: auto;
  padding: 10%;
  background-image: url('@/assets/images/Modals/BaseEncuestaUltima.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
}

.img-modal {
  display: flex;
  width: 55vw;
  align-items: center;
  justify-content: center;
}

.img-modal button {
  width: 90px;
  margin: 5px;
  padding: 5px;
  font-family: spaceAge;
  border: 0.1px solid grey;
}

.btnAceptar {
  background-color: #740105;
  color: white;
}

.btnCerrar {
  background-color: #aaa;
  color: black;
}

.closeModal {
  width: 75px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 20%;
}

.closeModal2 {
  width: 60px;
  cursor: pointer;
}

@media (min-width: 390px) {
  .div-text {
    margin-top: 40px;
  }

  .content {
    margin-top: 40px;
  }

  .send {
    margin-top: 40px;
  }

}

@media (min-width: 435px) {
  .img-modal {
    width: 45vw;
  }
}

@media (min-width: 490px) {
  .closeModal {
    right: 25%;
  }
}

@media (min-width: 575px) {
  .img-modal {
    width: 35vw;
  }

  .modal-content {
    padding: 7%;
  }
}

@media (min-width: 590px) {
  .closeModal {
    right: 30%;
  }
}

@media (min-width: 630px) {
  .modal-content {
    margin-bottom: 0px;
  }
}

@media (min-width: 685px) {
  .img-modal {
    width: 25vw;
  }
}

@media (min-width: 760px) {
  .closeModal {
    right: 35%;
  }
}

@media (min-width: 767px) {

  .content {
    width: 50%;
  }

  .terms {
    width: 70%;
  }

  .terms-div {
    margin-top: 15px;
  }

  .terms-div span {
    font-size: 16.5px;
  }

  .modal-content-final {
    padding: 6%;
  }

}

@media (min-width: 900px) {
  .modal-content {
    padding: 6%;
  }

  .modal-content-final {
    padding: 5%;
  }
}

@media (min-width: 960px) {
  .img-modal {
    width: 20vw;
  }
}

@media (min-width: 1100px) {
  .modal-content-final {
    padding: 4%;
  }
}

@media (min-width: 1250px) {
  .closeModal {
    bottom: 20px;
    right: 40%;
  }
}

@media (min-width: 1500px) {
  .title {
    width: 100%;
  }

  .div-text {
    margin-top: 70px;
  }

  .content {
    margin-top: 50px;
  }

  .form-inputs input {
    font-size: 20px;
  }

  .terms {
    width: 60%;
  }

  .terms-div {
    margin-top: 18px;
  }

  .terms-div span {
    font-size: 18.5px;
  }

  .send {
    margin-top: 70px;
  }

  .modal-content {
    padding: 4%;
  }

  .img-modal {
    width: 15vw;
  }

  .modal-content-final {
    padding: 3%;
  }

  .closeModal2 {
    width: 70px;
  }
}

@media (min-width: 1696px) {
  .closeModal {
    right: 42%;
  }
}

@media (min-width: 2000px) {
  .img-modal {
    width: 10vw;
  }

  .modal-content {
    padding: 2%;
  }

  .modal-content-final {
    padding: 2%;
  }
}

@media (min-width: 3000px) {
  .img-modal {
    width: 7vw;
  }

  .modal-content-final {
    padding: 1.5%;
  }
}
</style>