import './assets/css/index.css'
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router/index';
import pinia from './pinia/index';

const app = createApp(App)

app.use(i18n)
app.use(pinia)
app.use(router);

app.mount('#app')

/*  TODO: ReCaptCha  */
// window.onCaptchaResolved = function (token) {
//     localStorage.setItem('tokenReCaptCha', token);
// }

// window.onCaptchaExpired = function () {
//     localStorage.removeItem('tokenReCaptCha');
// }
