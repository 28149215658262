// src/i18n.js
import { createI18n } from 'vue-i18n'
import es from '@/assets/locales/es.json'

const messages = {
  es
}

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages,
  warnHtmlInMessage: 'off'
})

export default i18n
