<template>
  <div class="navbar">
    <img v-if="!isMenuContentVisible" class="menu-button" :src="menuHamg" alt="menuHamg" @click="toggleMenu">
    <img v-if="isMenuContentVisible" class="menu-button" :src="menuClose" alt="menuClose" @click="toggleMenu">
    <div class="menu" :class="{ 'show': showMenu }">
      <div class="div-close">
        <img class="menu-button-close" :src="menuClose" alt="menuClose" @click="toggleMenu">
      </div>
      <ul>
        <li @click="handleLandingScanClick">
          {{ $t('landingScan.menu.text1') }}
          <!-- <router-link v-if="mobile != ''" to="/landing-scan">{{ $t('landingScan.menu.text1') }}</router-link>
          <router-link v-if="mobile === ''" to="/landing-access">{{ $t('landingScan.menu.text1') }}</router-link> -->
        </li>
        <li><router-link to="/terms_Conditions">{{ $t('landingScan.menu.text3') }}</router-link></li>
        <li><a href="https://www.pmiprivacy.com/spain/es/consumer/" target="_blank">{{ $t('landingScan.menu.text4')
            }}</a></li>
        <li><router-link to="/legal">{{ $t('landingScan.menu.text2') }}</router-link></li>
        <li><router-link to="/contact">{{ $t('landingScan.menu.text5') }}</router-link></li>
        <li><router-link to="/faq">{{ $t('landingScan.menu.text6') }}</router-link></li>
      </ul>
    </div>
  </div>

</template>

<script>
import { useInfoStore } from '@/stores/useInfoStore';

export default {
  name: 'MenuContent',
  props: {
    isMenuContentVisible: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    const infoStore = useInfoStore();
    return {
      menuHamg: require('@/assets/images/MenuHamburguesa.png'),
      menuClose: require('@/assets/images/Cerrar.png'),
      showMenu: false,
      infoStore: infoStore,
      mobile: infoStore.mobile,
    }
  },
  methods: {
    async handleLandingScanClick(event) {
      event.preventDefault(); 

      this.infoStore.resetData();
      
      if (this.infoStore.mobile != '') {
        this.$router.push({ name: 'LandingScan' });
      } else {
        this.$router.push({ name: "LandingAccess" });
      }

    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  }
}
</script>

<style scoped>
.navbar {
  display: flex;
  position: relative;
  width: 95%;
  padding: 10px;
  justify-content: flex-end;
}

.menu-button {
  cursor: pointer;
  width: 25px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: none;
  height: auto;

  height: auto;
  background-image: url('/src/assets/images/Fondo.png');
  background-size: cover;
  background-position: center;
}

.div-close {
  display: flex;
  position: relative;
  width: 97%;
  padding: 10px;
  justify-content: flex-end;
}

.menu-button-close {
  width: 25px;
  cursor: pointer;
  margin-right: 5px;
}

.menu.show {
  display: block;
}

.menu ul {
  list-style-type: none;
  padding: 8%;
  text-align: end;
}

.menu ul li {
  font-family: workSans-bold;
  font-weight: bold;
  padding: 10px;
  font-size: 18px;
}

.menu ul li a {
  color: aliceblue !important;
  text-decoration: none;
}

.menu ul li a:hover {
  text-decoration: none;
  color: orange !important;
}

@media (min-width: 520px) {

  .menu {
    height: auto;
    background-image: url('/src/assets/images/FondoGrande.png');
    background-size: cover;
    background-position: center;
  }

  .menu ul {
    padding: 5%;
    padding-top: 10px;
  }
}

@media (min-width: 767px) {

  .menu ul li {
    font-size: 20px;
  }

}

@media (min-width: 900px) {
  .menu ul {
    width: 95%;
    padding: 2%;
  }
}

@media (min-width: 1500px) {

  .menu ul li {
    font-size: 25px;
  }

}
</style>