<template>
    <div class="home">

        <img class="title" :src="titulo" alt="titulo">


        <div class="div-text">
            <div class="span1">
                <span>
                    <b>{{ $t('counter.divText.text1') }}</b>
                </span>
            </div>

            <div class="span2">
                <span>
                    <b>{{ $t('counter.divText.text2') }}</b>
                    <br/>
                    <span>{{ $t('counter.divText.text3') }}</span>
                    <br/>
                    <b>{{ $t('counter.divText.text6') }}</b>
                </span>
            </div>
        </div>

        <div class="container">
            <div class="pCounter">
                <div class="first" id="counterDays">00</div>
                <div class="second" id="counterHours">00</div>
                <div>:</div>
                <div class="second" id="counterMins">00</div>
                <div>:</div>
                <div class="second" id="counterSecs">00</div>
            </div>

            <div class="labelsCounter">
                <label for="counterDays">{{ $t('counter.day') }}</label>
                <div class="labelCounterMin">
                    <label for="counterHours">{{ $t('counter.hours') }}</label>
                    <label for="counterMins">{{ $t('counter.minutes') }}</label>
                    <label for="counterSecs">{{ $t('counter.seconds') }}</label>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
    name: 'CounterView',
    data() {
        return {
            titulo: require('@/assets/images/Titulo.png'),
            counter: require('@/assets/images/CounterView/RelojCounter.png'),
        }
    },
    setup() {
        const DATE_TARGET = new Date('10/10/2024 10:10');
        const MILLISECONDS_OF_A_SECOND = 1000;
        const MILLISECONDS_OF_A_MINUTE = MILLISECONDS_OF_A_SECOND * 60;
        const MILLISECONDS_OF_A_HOUR = MILLISECONDS_OF_A_MINUTE * 60;
        const MILLISECONDS_OF_A_DAY = MILLISECONDS_OF_A_HOUR * 24


        function updateCountdown(p_Days, p_Hours, p_Mins, p_Secs) {
            // Calcs
            const NOW = new Date()
            const DURATION = DATE_TARGET - NOW;
            const REMAINING_DAYS = Math.floor(DURATION / MILLISECONDS_OF_A_DAY);
            const REMAINING_HOURS = Math.floor((DURATION % MILLISECONDS_OF_A_DAY) / MILLISECONDS_OF_A_HOUR);
            const REMAINING_MINUTES = Math.floor((DURATION % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE);
            const REMAINING_SECONDS = Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND);


            // Render
            p_Days.innerText = REMAINING_DAYS;
            p_Hours.innerText = REMAINING_HOURS;
            p_Mins.innerText = REMAINING_MINUTES;
            p_Secs.innerText = REMAINING_SECONDS;
            if (REMAINING_DAYS == 0 && REMAINING_HOURS == 0 && REMAINING_MINUTES == 0 && REMAINING_SECONDS == 0) { window.location.href = "/"; }
        }

        onMounted(async () => {
            const p_Days = document.getElementById('counterDays');
            const p_Hours = document.getElementById('counterHours');
            const p_Mins = document.getElementById('counterMins');
            const p_Secs = document.getElementById('counterSecs');

            if (p_Days && p_Hours && p_Mins && p_Secs) {
                updateCountdown(p_Days, p_Hours, p_Mins, p_Secs);
                setInterval(() => updateCountdown(p_Days, p_Hours, p_Mins, p_Secs), MILLISECONDS_OF_A_SECOND);
            } else {
                console.error('Error: Uno o más elementos del DOM no se encontraron.');
            }
        });
    },
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    min-height: auto;

    font-family: dollydots;
    color: aliceblue;
}

.title {
    width: 90%;
    max-width: 2150px;
    margin-top: 30px;
}

.div-text {
    display: flex;
    width: 70%;
    color: #aaa;
    font-size: 30px;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
}

.span1 {
    font-family: workSans-medium;
    text-align: center;
    color: #aaa;
    font-size: 30px;
    line-height: 1;
    margin-top: 50px;
    
}
.span1 b, .span2 b {
    color: white;
}
.span2 {
    font-family: workSans-medium;
    text-align: center;
    color: #aaa;
    font-size: 15px;
    margin-top: 25px;

    
}

.container {
    text-align: center;
    width: 90%;
    padding: 5%;
    margin-top: 50px;
    background-image: url('@/assets/images/CounterView/RelojCounter.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    box-sizing: border-box;
}

.pCounter {
    font-size: 35px;
    display: flex;
    margin-bottom: -5px;
    width: 90%;
    align-items: baseline;
    justify-content: center;
}

.first {
    color: orange;
    font-size: 45px;
    margin-top: 5px;
    width: 25%;
    display: flex;
    justify-content: center;
}

.second {
    width: 20%;
    display: flex;
    justify-content: center;
}

.labelsCounter {
    display: flex;
    font-family: workSans-semiBold;
    font-size: 15px;
    gap: 5%;
    justify-content: space-around;
}

.labelCounterMin {
    display: flex;
    gap: 10%;
    font-size: 10px;
    transform: translateX(-30px);
}




@media (min-width: 360px) {
    .container {
        width: 85%;
    }

    .labelCounterMin {
        transform: translateX(-40px);
    }
}

@media (min-width: 390px) {
    .span2 {
        margin-top: 40px;
    }


}

@media (min-width: 412px) {
    .labelCounterMin {
        transform: translateX(-50px);
    }
}

@media (min-width: 430px) {
    .container {
        width: 80%;
    }
}

@media (min-width: 500px) {
    .container {
        width: 70%;
    }
}

@media (min-width: 578px) {
    .container {
        width: 65%;
    }

    .span1 {
        width: 90%;
    }

    .labelCounterMin {
        transform: translateX(-50px);
    }
}

@media (min-width: 642px) {
    .container {
        width: 60%;
    }

    .pCounter {
        font-size: 45px;
        gap: 2%
    }

    .first {
        font-size: 65px;
        width: 30%;
    }

    .second {
        width: 25%;
    }

    .labelsCounter {
        font-size: 16px;
    }

    .labelCounterMin {
        font-size: 11px;
    }
}

@media (min-width: 767px) {
    .container {
        width: 50%;
    }

    .span1 {
        font-size: 42px;
    }

    .span2 {
        font-size: 17px;
    }
}

@media (min-width: 900px) {
    .container {
        width: 45%;
    }
}

@media (min-width: 1008px) {
    .container {
        width: 40%;
        padding-left: 2%;
    }

    .pCounter {
        font-size: 50px;
        width: 100%;
    }

    .first {
        font-size: 70px;
        width: 35%;
    }

    .second {
        width: 30%;
    }

    .labelCounterMin {
        font-size: 11px;
        gap: 25%;
        transform: translateX(-50px);
    }

}

@media (min-width: 1500px) {

    .span1{
        font-size: 44px;
    }

    .span2 {
        margin-top: 40px;
        font-size: 19px;
    }


}
</style>