import { defineStore } from "pinia";

export const useRequestStore = defineStore("useRequest", {
  state: () => ({}),

  actions: {
    //TODO: Peticion que permite obtener el estado inicial de la aplicación
    async getStateAplication() {
      const url = process.env.VUE_APP_BASEURL + "pms/adventure/utils/timing";
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        headers: {
          TokenCrypt: key,
        },
        cache:'reload',
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.status === "success") {
            return data.data;
          } else {
            console.error("Error: Estado no exitoso", data.status);
            throw new Error("La peticion ha fallado");
          }
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: Peticion  de landingAccess
    async postLandingAccess(mobile) {
      const url = process.env.VUE_APP_BASEURL + "pms/adventure/landingaccess";

      const formData = new FormData();
      formData.append("mobile", mobile);
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        method: "POST",
        headers: {
          TokenCrypt: key,
        },
        cache:'reload',
        body: formData,
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.status === "success") {
            return data.data;
          } else {
            console.error("Error: Estado no exitoso", data.status);
            throw new Error("La peticion ha fallado");
          }
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: Peticion  de landingScan
    async postLandingScan(data) {
      const url = process.env.VUE_APP_BASEURL + "pms/adventure/landingscan";

      // const formData = this.createFormDataNew(data);
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          TokenCrypt: key,
        },
        cache:'reload',
        body: JSON.stringify(data),
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: Peticion  de getParameterData
    async getParameterData(token) {
      const url =
        process.env.VUE_APP_BASEURL +
        "pms/adventure/getParameterData?urlParameter=" +
        token;
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        headers: {
          TokenCrypt: key,
        },
        cache:'reload',
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: Peticion de register
    async postRegister(data) {
      const url = process.env.VUE_APP_BASEURL + "pms/adventure/register";

      const formData = this.createFormData(data);
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        method: "POST",
        headers: {
          TokenCrypt: key,
        },
        cache:'reload',
        body: formData,
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: Peticion de confirmPrize
    async postConfirmPrize(data) {
      const url = process.env.VUE_APP_BASEURL + "pms/adventure/confirmPrize";

      const formData = this.createFormData(data);
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        method: "POST",
        headers: {
          TokenCrypt: key,
        },
        cache:'reload',
        body: formData,
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: Peticion de Contact
    async postContact(data) {
      const url = process.env.VUE_APP_BASEURL + "pms/adventure/contact";

      const formData = this.createFormData(data);
      var key = this.generateTokenWithTwoChecksumDigits();
      const options = {
        method: "POST",
        headers: {
          TokenCrypt: key,
        },
        cache:'reload',
        body: formData,
      };

      return await fetch(url, options)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    },

    //TODO: POST para la verificacion del reCaptCha
    async postReCaptCha(data) {
      try {
        const response = await fetch(
          "https://www.google.com/recaptcha/api/siteverify",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `secret=${process.env.VUE_APP_KEYCAPTCHA}&response=${data}`,
          }
        );
        const result = await response.json();

        if (result.success) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error al enviar captcha:", error);
      }
    },

    //TODO: METODOS AUXILIARES
    createFormData(options) {
      const formData = new FormData();

      const keyList = Object.keys(options);
      for (const key of keyList) {
        const value = options[key];

        Array.isArray(value)
          ? value.forEach((item) => formData.append(key, item))
          : formData.append(key, value);
      }

      return formData;
    },
    generateTokenWithTwoChecksumDigits(length = 30, controlPosition = null) {
      // Generar los primeros 28 caracteres del token (sin los dos dígitos de control)
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let tokenBase = ''
      for (let i = 0; i < length - 2; i++) {
        tokenBase += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      // Calcular los dos dígitos de control basados en los valores ASCII de los primeros 10 caracteres
      let sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += tokenBase.charCodeAt(i);
      }
      
      // Usamos el resultado para generar dos dígitos de control
      let checksum1 = Math.floor((sum % 100) / 10); // Primer dígito (decena)
      let checksum2 = sum % 10;                    // Segundo dígito (unidad)

      // Definir las posiciones del dígito de control, si no están definidas, se ponen al final
      if (controlPosition === null || controlPosition >= length - 1) {
        controlPosition = length - 2; // Colocar en las últimas dos posiciones por defecto
      }

      // Insertar los dos dígitos de control en las posiciones especificadas
      const tokenWithChecksum = tokenBase.slice(0, controlPosition) 
          + checksum1.toString() 
          + checksum2.toString() 
          + tokenBase.slice(controlPosition);

      return tokenWithChecksum;
    }
  },
});
