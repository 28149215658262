<template>
  <MenuContent></MenuContent>
  <div class="home">
    <img class="title" :src="titulo" alt="titulo">
    <div class="div-text">
      <span>
        <b>{{ $t('landingAccess.title') }}</b>
      </span>
    </div>
    <form ref="myForm" class="content" @submit.prevent="validate">
      <div class="div-input">
        <input v-model="mobile" type="text" maxlength="9" :placeholder="$t('landingAccess.textPlaceholder')" required />
      </div>
      <div class="div-text2">
        <span>
          {{ $t('landingAccess.text1.text1') }}
          {{ $t('landingAccess.text1.text2') }}
          {{ $t('landingAccess.text1.text3') }}
          {{ $t('landingAccess.text1.text4') }}
        </span>
      </div>
      <div class="terms">
        <div class="terms-div" @click="handleTermsChange">
          <input class="terms-input1" type="checkbox" v-model="termsAccepted" id="terms" required />
          <span>
            {{ $t('generic.termsCheck.text1') }}
            <span class="legalText">{{ $t('generic.termsCheck.text2') }}</span>
            {{ $t('generic.termsCheck.text3') }}
          </span>
        </div>
        <div class="terms-div">
          <input class="terms-input2" type="checkbox" v-model="policyAccepted" id="policy" required />
          <span>
            {{ $t('generic.policyText.text1') }}
            <a href="https://www.pmiprivacy.com/spain/es/consumer/" target="_blank">{{
      $t('generic.policyText.text2') }}</a>
            {{ $t('generic.policyText.text3') }}
            <span @click="openUsageTermsModal" class="legalText">{{ $t('generic.policyText.text4') }}</span>
            {{ $t('generic.policyText.text5') }}
          </span>
        </div>
      </div>

      <!-- TODO: ReCaptCha -->
      <!-- <div class="captCha">
        <div class="g-recaptcha" :data-sitekey="captChaKey" data-callback="onCaptchaResolved" data-expired-callback="onCaptchaExpired"></div>
      </div> -->

      <img @click.prevent="Submit" class="send" :class="{ disabled: isSubmitDisabled }" :src="send" alt="send">
    </form>
  </div>


  <!-- Modal de bases legales de promocion -->
  <div v-if="showTermsModal" class="modal">
    <div class="modal-content">
      <TermsConditions :titleModal="$t('generic.titleModal2.title1')" :menuContentVisible="false" styleModal="-modal"
        styleModalButtons="modalButtons" @scrolled-to-bottom="handleScrollToBottom">
      </TermsConditions>
      <div v-if="buttonsVisible" class="img-modal">
        <button class="btnAceptar" @click="closeModalOK('showTermsModal')">ACEPTAR</button>
        <button class="btnCerrar" @click="closeModalKO('showTermsModal')">CERRAR</button>
      </div>
    </div>
  </div>
  <!-- Modal de términos de uso -->
  <div v-if="showUsageTermsModal" class="modal">
    <div class="modal-content">
      <LegalWarning :titleModal="$t('generic.titleModal2.title2')" :menuContentVisible="false" styleModal="-modal">
      </LegalWarning>
      <div class="img-modal">
        <button class="btnAceptar" @click="closeModalOK('showUsageTermsModal')">ACEPTAR</button>
        <button class="btnCerrar" @click="closeModalKO('showUsageTermsModal')">CERRAR</button>
      </div>
    </div>
  </div>
  <!--Modal error-->
  <div v-if="showError" class="modal">
    <div class="modal-content-final">
      <ModalWarning :textModal="textError"></ModalWarning>
      <div class="img-modal">
        <button class="btnAceptar" @click="closeModal('showError')">CERRAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import TermsConditions from "@/components/TermsConditions.vue";
import LegalWarning from "@/components/LegalWarning.vue";
import ModalWarning from "@/components/Modals/ModalWarning.vue";
import { useInfoStore } from '@/stores/useInfoStore';
import { useRequestStore } from "@/stores/useRequestsStore";
import MenuContent from "@/components/MenuContent.vue";

export default {
  name: 'LandingAccessView',
  data() {
    const infoStore = useInfoStore();
    const requestStore = useRequestStore();
    return {
      titulo: require('@/assets/images/Titulo.png'),
      send: require('@/assets/images/Enviar.png'),
      infoStore: infoStore,
      requestStore: requestStore,
      mobile: '',
      termsAccepted: false,
      policyAccepted: false,
      showTermsModal: false,
      showUsageTermsModal: false,
      showError: false,
      textError: '',
      buttonsVisible: false,
      isSubmitDisabled: false,
      // captChaKey: process.env.VUE_APP_KEYCAPTCHA, /* TODO: ReCaptCha */
    };
  },
  components: {
    ModalWarning,
    TermsConditions,
    LegalWarning,
    MenuContent,
  },
  mounted() {
    this.preloadImage(require('@/assets/images/Legal/CapaLegales.png'));
    this.preloadImage(require('@/assets/images/Modals/BaseEncuesta.png'));

    /* TODO: ReCaptCha */
    // const recaptchaScript = document.createElement('script');
    // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    // document.head.appendChild(recaptchaScript);
  },
  methods: {
    preloadImage(imagePath) {
      const img = new Image();
      img.src = imagePath;
    },
    Submit() {
      if (this.isSubmitDisabled) return;

      const form = this.$refs.myForm;
      const event = new Event('submit', { cancelable: true });
      form.addEventListener('submit', (e) => e.preventDefault());
      form.dispatchEvent(event);  // Despacha el evento submit
    },
    async validate() {
      const form = this.$refs.myForm;
      if (form.checkValidity()) {
        const mobileRegex = /^(?!666666666|777777777)[67]\d{8}$/;
        if (this.mobile.length !== 9) {
          this.showError = true;
          this.textError = this.$t('landingAccess.modalErrors.text1');
          return;
        } else if (!/^\d+$/.test(this.mobile)) {
          this.showError = true;
          this.textError = this.$t('landingAccess.modalErrors.text2');
          return;
        } else if (this.mobile === "666666666" || this.mobile === "777777777") {
          this.showError = true;
          this.textError = this.$t('landingAccess.modalErrors.text3');
          return;
        } else if (!mobileRegex.test(this.mobile)) {
          this.showError = true;
          this.textError = this.$t('landingAccess.modalErrors.text4');
          return;
        } else {
          this.isSubmitDisabled = true;

          /*  TODO: ReCaptCha  */
          // const tokenReCaptCha = localStorage.getItem('tokenReCaptCha') ? localStorage.getItem('tokenReCaptCha') : null;

          // if (tokenReCaptCha != null) {

          //   const reCaptCha = await this.requestStore.postReCaptCha(tokenReCaptCha);

          //   if (reCaptCha) {

          //     this.infoStore.setValue(this.mobile, 'mobile');
          //     try {
          //       // En esta constante, llamamos al back para que nos diga si es el 4 intento y asi obligarle a ir a la pagina correspondiente
          //       const result = await this.requestStore.postLandingAccess(this.mobile);
          //       if (!result.scanLimitExceeded && !result.locked && !result.exceedsMaxAttempts) {
          //         //Procedemos a guardar toda la información obtenida de la peticion
          //         this.infoStore.setValue(result.scanLimitExceeded, 'scanLimitExceeded');
          //         this.infoStore.setValue(result.getStoreCode, 'getStoreCode');
          //         this.infoStore.setValue(result.getEmployeeCode, 'getEmployeeCode');
          //         this.infoStore.setValue(result.needTest, 'needTest');
          //         if (result.countParticipation >= 5) {
          //           this.infoStore.setValue(!result.needTest, 'testComplee');
          //         }
          //         this.infoStore.setValue(result.countParticipation, 'countParticipation');
          //         this.$router.push({
          //           name: 'LandingScan',
          //         });
          //       } else if (result.scanLimitExceeded) {
          //         this.$router.push({
          //           name: 'NoMoreForToday',
          //           params: {
          //             noReturn: true,
          //           },
          //         });
          //       } else if (result.locked) {
          //         this.$router.push({
          //           name: '20Errors',
          //           params: {
          //             noReturn: true,
          //           },
          //         });
          //       } else if (result.exceedsMaxAttempts) {
          //         this.$router.push({
          //           name: 'MaximumExceeded',
          //           params: {
          //             noReturn: true,
          //           },
          //         });
          //       }
          //     } catch (error) {
          //       throw new Error("La peticion de Landing Access ha fallado");
          //     }

          //   } else {
          //     this.showError = true;
          //     this.textError = this.$t('landingAccess.modalErrors.textRecaptCha');
          //     return;
          //   }

          // } else {
          //   this.showError = true;
          //   this.textError = this.$t('landingAccess.modalErrors.textRecaptCha');
          //   return;
          // }

          /*  TODO: ReCaptCha Comentar  */
          this.infoStore.setValue(this.mobile, 'mobile');
          try {
            // En esta constante, llamamos al back para que nos diga si es el 4 intento y asi obligarle a ir a la pagina correspondiente
            const result = await this.requestStore.postLandingAccess(this.mobile);
            if (!result.scanLimitExceeded && !result.locked && !result.exceedsMaxAttempts) {
              this.isSubmitDisabled = false;
              //Procedemos a guardar toda la información obtenida de la peticion
              this.infoStore.setValue(result.scanLimitExceeded, 'scanLimitExceeded');
              this.infoStore.setValue(result.getStoreCode, 'getStoreCode');
              this.infoStore.setValue(result.getEmployeeCode, 'getEmployeeCode');
              this.infoStore.setValue(result.needTest, 'needTest');
              if (result.countParticipation >= 5) {
                this.infoStore.setValue(!result.needTest, 'testComplee');
              }
              this.infoStore.setValue(result.countParticipation, 'countParticipation');
              this.$router.push({
                name: 'LandingScan',
              });
            } else if (result.scanLimitExceeded) {
              this.isSubmitDisabled = false;
              this.$router.push({
                name: 'NoMoreForToday',
                params: {
                  noReturn: true,
                },
              });
            } else if (result.locked) {
              this.isSubmitDisabled = false;
              this.$router.push({
                name: '20Errors',
                params: {
                  noReturn: true,
                },
              });
            } else if (result.exceedsMaxAttempts) {
              this.isSubmitDisabled = false;
              this.$router.push({
                name: 'MaximumExceeded',
                params: {
                  noReturn: true,
                },
              });
            }
          } catch (error) {
            this.isSubmitDisabled = false;
            throw new Error("La peticion de Landing Access ha fallado");
          }
        }
      } else {
        form.reportValidity();
      }
    },
    handleTermsChange() {
      this.showTermsModal = true;
      this.buttonsVisible = false;
    },
    openUsageTermsModal() {
      this.showUsageTermsModal = true;
    },
    closeModal(modal) {
      this[modal] = false;
    },
    closeModalOK(modal) {
      if (modal == 'showTermsModal') { this.termsAccepted = true }
      else { this.policyAccepted = true }
      this.closeModal(modal);
    },
    closeModalKO(modal) {
      if (modal == 'showTermsModal') { this.termsAccepted = false }
      else { this.policyAccepted = false }
      this.closeModal(modal);
    },
    handleScrollToBottom() {
      this.buttonsVisible = true;
    }

  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
  font-family: workSans-medium;
}

.title {
  width: 90%;
  margin-top: 10px;
}

.div-text {
  text-align: center;
  width: 80%;
  color: #aaa;
  font-size: 28px;
  margin-top: 40px;
  line-height: 0.9;
}

.div-text b {
  color: white;
}

.content {
  display: flex;
  flex-flow: column;
  width: 80%;
  align-items: center;
  margin-top: 10px;
}

.div-input {
  width: 300px;
}

.div-input input {
  width: -webkit-fill-available;
  padding: 15px;
  font-size: 22px;
  margin: 20px 0px 7px 0px;
  color: #aaa;
  text-align: center;
}

.div-input input::placeholder {
  font-size: 18px;
}

.div-input input:-webkit-autofill,
.div-input input:-webkit-autofill:hover,
.div-input input:-webkit-autofill:focus,
.div-input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.div-text2 {
  text-align: center;
  color: white;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 25px;
}

.terms {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.terms-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5px;
  column-gap: 10px;
}

.terms-div .terms-input1 {
  transform: scale(1.4);
  margin-top: 5px;
}

.terms-div .terms-input2 {
  transform: scale(1.4);
  margin-top: 5px;
}

.terms-div span {
  font-size: 14.5px;
  color: #aaa;
}

.terms-div b {
  color: white;
}

.terms-div span .legalText {
  cursor: pointer;
  color: white;
}

.terms-div a {
  color: white !important;
  text-decoration: none;
}

.send {
  width: 250px;
  cursor: pointer;
  margin-top: 30px;
}

.send.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.captCha {
  margin-top: 30px;
}

.div-text3 {
  text-align: center;
  width: 70%;
  color: #aaa;
  font-size: 9px;
  margin-top: 10px;
}

.div-text3 b {
  color: white;
}

.span-text {
  font-family: avenir;
  font-size: 8px;
  text-align: justify;
  width: 70%;
  color: #aaa;
  margin-top: 10px;
}

.span-text b {
  color: white;
}

.span-text a {
  text-decoration: none;
}

/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1002;
}

.modal-content {
  display: flex;
  width: 100vw;
  height: 75vh;
  overflow-y: auto;
  padding: 10%;
  background-image: url('@/assets/images/Legal/CapaLegales.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: relative;
  margin-bottom: 80px;
}

.modal-content-final {
  font-family: spaceAge;
  display: flex;
  width: 100vw;
  height: auto;
  padding: 10%;
  background-image: url('@/assets/images/Modals/BaseEncuestaUltima.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
}

.img-modal {
  display: flex;
  width: 55vw;
  align-items: center;
  justify-content: center;
}

.img-modal button {
  width: 90px;
  margin: 5px;
  padding: 5px;
  font-family: spaceAge;
  border: 0.1px solid grey;
}

.btnAceptar {
  background-color: #740105;
  color: white;
}

.btnCerrar {
  background-color: #aaa;
  color: black;
}

.closeModal {
  width: 75px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 20%;
}

.closeModal2 {
  width: 60px;
  cursor: pointer;
}

@media (min-width: 390px) {
  .div-text {
    margin-top: 40px;
  }

  .content {
    margin-top: 40px;
  }

  .send {
    margin-top: 40px;
  }

  .div-text3 {
    margin-top: 40px;
  }

  .span-text {
    margin-top: 40px;
  }

}

@media (min-width: 435px) {
  .img-modal {
    width: 45vw;
  }
}

@media (min-width: 490px) {
  .closeModal {
    right: 25%;
  }
}

@media (min-width: 575px) {
  .img-modal {
    width: 35vw;
  }

  .modal-content {
    padding: 7%;
  }

  .modal-content-final {
    padding: 7%;
  }
}

@media (min-width: 590px) {
  .closeModal {
    right: 30%;
  }
}

@media (min-width: 630px) {
  .modal-content {
    margin-bottom: 0px;
  }
}

@media (min-width: 685px) {
  .img-modal {
    width: 25vw;
  }
}

@media (min-width: 760px) {
  .closeModal {
    right: 35%;
  }
}

@media (min-width: 767px) {
  .content {
    width: 50%;
  }

  .div-text2 {
    font-size: 12px;
  }

  .terms {
    width: 70%;
  }

  .terms-div {
    margin-top: 15px;
  }

  .terms-div span {
    font-size: 16.5px;
  }

  .div-text3 {
    font-size: 12px;
  }

  .span-text {
    font-size: 13px;
    width: 50%;
  }

  .closeModal2 {
    width: 65px;
  }

  .modal-content-final {
    padding: 6%;
  }

}

@media (min-width: 900px) {
  .modal-content {
    padding: 6%;
  }

  .modal-content-final {
    padding: 5%;
  }
}

@media (min-width: 960px) {
  .img-modal {
    width: 20vw;
  }
}

@media (min-width: 1100px) {
  .modal-content-final {
    padding: 4%;
  }
}

@media (min-width: 1250px) {
  .closeModal {
    bottom: 20px;
    right: 40%;
  }
}

@media (min-width: 1500px) {
  .title {
    width: 100%;
  }

  .div-text {
    margin-top: 70px;
  }

  .content {
    margin-top: 50px;
  }

  .div-input input {
    font-size: 20px;
  }

  .div-text2 {
    font-size: 15px;
  }

  .terms {
    width: 60%;
  }

  .terms-div {
    margin-top: 18px;
  }

  .terms-div span {
    font-size: 18.5px;
  }

  .div-text3 {
    font-size: 15px;
  }

  .span-text {
    font-size: 15px;
  }

  .span-text {
    margin-top: 70px;
  }

  .modal-content {
    padding: 4%;
  }

  .img-modal {
    width: 15vw;
  }

  .modal-content-final {
    padding: 3%;
  }

  .closeModal2 {
    width: 70px;
  }
}

@media (min-width: 1696px) {
  .closeModal {
    right: 42%;
  }
}

@media (min-width: 2000px) {
  .img-modal {
    width: 10vw;
  }

  .modal-content {
    padding: 2%;
  }

  .modal-content-final {
    padding: 2%;
  }
}

@media (min-width: 3000px) {
  .img-modal {
    width: 7vw;
  }

  .modal-content-final {
    padding: 1.5%;
  }
}
</style>
