<template>
  <MenuContent></MenuContent>
  <div class="home">
    <form ref="myForm" class="content" @submit.prevent="checkPrize">
      <img class="title" :src="titulo" alt="titulo">
      <div class="div-text">
        <span>
          <b>{{ $t('landingScan.title') }}</b>
        </span>
      </div>
      <div class="input-group">
        <EanScan></EanScan>
        <TtScan></TtScan>
      </div>
      <div class="divInfograma">
        <img class="scanImage" :src="scanImage" alt="scanImage">
      </div>
      <div class="div-text2">
        <span>
          <b>{{ $t('landingScan.text.text3') }}</b>
        </span>
      </div>
      <div class="input-group2">
        <div v-if="showTextOne" ref="floatingOne" class="floatingStylesOne tooltip1">{{
      $t('landingScan.modals.infoCStore') }}</div>
        <div class="divLine" v-if="!showStoreCode">
          <input class="inputCodes" v-model="storeCode" type="number" @input="limitDigitsStoreCode"
            :placeholder="$t('landingScan.modals.storeCode')" />
          <img class="iInfo1" ref="svgIconOne" :src="iInfo" alt="iInfo1" @mouseover="showTextOne = true"
            @mouseleave="showTextOne = false">
        </div>
        <div v-if="showTextTwo" ref="floatingTwo" class="floatingStylesTwo tooltip2">{{
      $t('landingScan.modals.infoCEmployee') }}</div>
        <div class="divLine" v-if="!showEmployeeCode">
          <input class="inputCodes" v-model="employeeCode" type="number" @input="limitDigitsEmployeeCode"
            :placeholder="$t('landingScan.modals.employeeCode')" />
          <img class="iInfo2" ref="svgIconTwo" :src="iInfo" alt="iInfo2" @mouseover="showTextTwo = true"
            @mouseleave="showTextTwo = false">
        </div>
      </div>
      <div class="div-text3">
        <span>
          <b>{{ $t('landingAccess.text2.text1') }}</b>
          <br>
          <b>{{ $t('landingAccess.text2.text2') }}</b>
          <br>
          {{ $t('landingAccess.text2.text3') }}
        </span>
      </div>
      <img @click.prevent="Submit" class="bSend" :class="{ disabled: isSubmitDisabled }" :src="bSend" alt="bSend">
    </form>
  </div>
  <!-- Modal test satisfaccion -->
  <div v-if="showSatisfactionTestModal1" class="modal">
    <div class="modal-content">
      <Question1></Question1>
      <div class="img-modal">

        <button class="btnCerrar" @click="closeModalSatisfaction('showSatisfactionTestModal1')">CERRAR</button>
        <button class="btnAceptar"
          @click="continueModal('showSatisfactionTestModal1', 'showSatisfactionTestModal2')">CONTINUAR</button>
      </div>
    </div>
  </div>
  <div v-if="showSatisfactionTestModal2" class="modal">
    <div class="modal-content">
      <Question2></Question2>
      <div class="img-modal">
        <button class="btnCerrar" @click="closeModalSatisfaction('showSatisfactionTestModal2')">CERRAR</button>
        <button class="btnAceptar"
          @click="continueModal('showSatisfactionTestModal2', 'showSatisfactionTestModal3')">CONTINUAR</button>
      </div>
    </div>
  </div>
  <div v-if="showSatisfactionTestModal3" class="modal">
    <div class="modal-content">
      <Question3></Question3>
      <div class="img-modal">
        <button class="btnCerrar" @click="closeModalSatisfaction('showSatisfactionTestModal3')">CERRAR</button>
        <button class="btnAceptar"
          @click="continueModal('showSatisfactionTestModal3', 'showSatisfactionTestModal4')">CONTINUAR</button>
      </div>
    </div>
  </div>
  <div v-if="showSatisfactionTestModal4" class="modal">
    <div class="modal-content-final">
      <Question4></Question4>
      <div class="img-modal">
        <button class="btnAceptar" @click="closeSatisfactionTest">CERRAR</button>
      </div>
    </div>
  </div>

  <!-- Modal errors -->
  <div v-if="showError" class="modal">
    <div class="modal-content-final">
      <ModalWarning :textModal="textError"></ModalWarning>
      <div class="img-modal">
        <button class="btnAceptar" @click="closeModal('showError')">CERRAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useInfoStore } from '@/stores/useInfoStore';
import { useRequestStore } from "@/stores/useRequestsStore";
import { storeToRefs } from 'pinia';
import { flip, offset, shift, useFloating } from '@floating-ui/vue';
import { ref } from 'vue';
import MenuContent from "@/components/MenuContent.vue";
import EanScan from "../LandingScan/componentsMenu/EanScan.vue";
import TtScan from "../LandingScan/componentsMenu/TtScan.vue";
import Question1 from "@/components/Questions/Question1.vue";
import Question2 from "@/components/Questions/Question2.vue";
import Question3 from "@/components/Questions/Question3.vue";
import Question4 from "@/components/Questions/Question4.vue";
import ModalWarning from "@/components/Modals/ModalWarning.vue";

export default {
  components: {
    MenuContent,
    EanScan,
    TtScan,
    Question1,
    Question2,
    Question3,
    Question4,
    ModalWarning
  },
  name: 'LandingScan',
  data() {
    const infoStore = useInfoStore();
    const requestStore = useRequestStore();
    const { eanCode, ttCode, getStoreCode, getEmployeeCode } = storeToRefs(infoStore);

    const showTextOne = ref(false)
    const svgIconOne = ref(null)
    const floatingOne = ref(null)

    const showTextTwo = ref(false)
    const svgIconTwo = ref(null)
    const floatingTwo = ref(null)

    const { floatingStyles: floatingStylesOne } = useFloating(svgIconOne, floatingOne, {
      placement: 'top',
      middleware: [offset(2), flip(), shift()],
    })

    const { floatingStyles: floatingStylesTwo } = useFloating(svgIconTwo, floatingTwo, {
      placement: 'top',
      middleware: [offset(2), flip(), shift()],
    })

    return {
      titulo: require('@/assets/images/Titulo.png'),
      scanImage: require('@/assets/images/actionsMalboroNEGRO.svg'),
      bSend: require('@/assets/images/ComprobarPremio.png'),
      iInfo: require('@/assets/images/IconoInfo.png'),
      close: require('@/assets/images/Modals/ModalCerrar.png'),
      infoStore: infoStore,
      requestStore: requestStore,
      eanCode: eanCode,
      ttCode: ttCode,
      showStoreCode: getStoreCode,
      storeCode: null,
      showEmployeeCode: getEmployeeCode,
      employeeCode: null,
      firstCuestion: '',
      secondCuestion: '',
      thirdCuestion: '',
      showExtraCodeModal: false,
      showSatisfactionTestModal1: false,
      showSatisfactionTestModal2: false,
      showSatisfactionTestModal3: false,
      showSatisfactionTestModal4: false,
      isFourthParticipation: false,
      showTextOne,
      floatingOne,
      floatingStylesOne,
      showTextTwo,
      floatingTwo,
      floatingStylesTwo,
      showError: false,
      textError: '',
      isSubmitDisabled: false,
    };
  },
  methods: {
    limitDigitsStoreCode() {
      const val = this.storeCode.toString().replace(/\D/g, '');
      if (val.length > 4) {
        this.storeCode = val.slice(0, 4);
      } else {
        this.storeCode = val;
      }
    },
    limitDigitsEmployeeCode() {
      const val = this.employeeCode.toString().replace(/\D/g, '');
      if (val.length > 2) {
        this.employeeCode = val.slice(0, 2);
      } else {
        this.employeeCode = val;
      }
    },
    Submit() {
      if (this.isSubmitDisabled) return;

      const form = this.$refs.myForm;
      const event = new Event('submit', { cancelable: true });
      form.addEventListener('submit', (e) => e.preventDefault());
      form.dispatchEvent(event);  // Despacha el evento submit
    },

    checkPrize() {

      if (this.eanCode != '' && this.ttCode != '') {

        if (this.infoStore.needTest || (this.infoStore.countParticipation >= 5 && !this.infoStore.testComplee)) {
          this.showSatisfactionTestModal1 = true;
          return;
        } else {
          this.sendInfo();
        }

      } else if (this.eanCode === '' && this.ttCode === '') {
        this.showError = true;
        this.textError = this.$t('landingScan.modalErrors.text1');
        return;
      } else if (this.eanCode === '' && this.ttCode != '') {
        this.showError = true;
        this.textError = this.$t('landingScan.modalErrors.text2');
        return;
      } else if (this.eanCode != '' && this.ttCode === '') {
        this.showError = true;
        this.textError = this.$t('landingScan.modalErrors.text3');
        return;
      }


    },
    closeSatisfactionTest() {
      this.closeModal('showSatisfactionTestModal4');
      this.sendInfo();
    },

    closeModalSatisfaction(modal) {
    if (modal === 'showSatisfactionTestModal1'){
      this.infoStore.setValue("", 'firstAsk');
    }
      this.closeModal(modal);
      this.sendInfo();
    },

    async sendInfo() {
      /* Proceso de recoger los datos y enviar la peticion al backend */
      const ageData = new Date(`${this.infoStore.year}-${this.infoStore.month}-01`);
      const day = ageData.getDate().toString().padStart(2, '0');
      const month = (ageData.getMonth() + 1).toString().padStart(2, '0');
      const year = ageData.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      const data = {
        mobile: this.infoStore.mobile,
        codeEan: this.infoStore.eanCode,
        codeTyT: btoa(this.infoStore.ttCode),
        urlCode: this.infoStore.urlCode,
        age: formattedDate,
      }

      this.isSubmitDisabled = true;

      if (!this.infoStore.getStoreCode && !this.infoStore.getEmployeeCode) {

        if (this.storeCode != null && this.employeeCode != null) {
          data.storeCode = this.storeCode;
          data.employeeCode = this.employeeCode;

        } else if (this.storeCode != null && this.employeeCode === null) {
          data.storeCode = this.storeCode;

        } else if (this.storeCode === null && this.employeeCode != null) {
          data.employeeCode = this.employeeCode;
        }

      } else if (!this.infoStore.getStoreCode && this.infoStore.getEmployeeCode) {

        if (this.storeCode != null) {
          data.storeCode = this.storeCode;
        }
      } else if (this.infoStore.getStoreCode && !this.infoStore.getEmployeeCode) {
        if (this.employeeCode != null) {
          data.employeeCode = this.employeeCode;
        }
      }

      if ((this.infoStore.needTest || this.infoStore.countParticipation >= 5) && this.infoStore.firstAsk != '' && !this.infoStore.testDataSend) {

        data.satisfactionTest = {
          firstAsk: this.infoStore.firstAsk,
          secondAsk: this.infoStore.secondAsk,
          thirdAsk: this.infoStore.thirdAsk
        }
        this.infoStore.setValue(false, 'needTest');
        this.infoStore.setValue(true, 'testComplee');
      }
      const result = await this.requestStore.postLandingScan(data);
      if (result.status === "success") {
        if (this.storeCode != null && this.employeeCode != null) {
          this.infoStore.setValue(!result.data.getStoreCode, 'getStoreCode');
          this.infoStore.setValue(!result.data.getEmployeeCode, 'getEmployeeCode');
        } else if (this.storeCode != null && this.employeeCode === null) {
          this.infoStore.setValue(!result.data.getStoreCode, 'getStoreCode');
        } else if (this.storeCode === null && this.employeeCode != null) {
          this.infoStore.setValue(!result.data.getEmployeeCode, 'getEmployeeCode');
        }

        if (this.infoStore.firstAsk != '' && !this.infoStore.testDataSend) {
          this.infoStore.setValue(true, 'testDataSend');
        }

        if (!result.data.scanLimitExceeded) {
          this.infoStore.setValue(this.infoStore.countParticipation + 1, 'countParticipation');
          this.isSubmitDisabled = false;
          this.infoStore.resetData();
          if (result.data.isWinner) {
            localStorage.setItem('canLoad', '1');
            switch (result.data.prize) {
              case 'rally':
                this.$router.push({
                  name: 'Rally',
                });
                break;
              case 'moto':
                this.$router.push({
                  name: 'Moto',
                });
                break;
              case 'catamarán':
                this.$router.push({
                  name: 'Catamarán',
                });
                break;
              case 'bono':
                this.$router.push({
                  name: 'Aladinia',
                });
                break;
              default:
                break;
            }
          } else {
            localStorage.setItem('canLoad', '0');
            switch (result.data.prize) {
              case 'nada':
                this.$router.push({
                  name: 'NoLuck',
                });
                break;
              case 'bloqueado':
                this.$router.push({
                  name: 'NoMoreForToday',
                  params: {
                    noReturn: true,
                  },
                });
                break;
              case 'repetido':
                this.$router.push({
                  name: 'CodeUsed',
                });
                break;
              case 'maximo':
                this.$router.push({
                  name: 'MaximumExceeded',
                  params: {
                    noReturn: true,
                  },
                });
                break;
              case 'erroneos':
                this.$router.push({
                  name: '20Errors',
                  params: {
                    noReturn: true,
                  },
                });
                break;
              default:
                break;
            }
          }
        } else {
          this.infoStore.resetData();
          this.isSubmitDisabled = false;
          this.$router.push({
            name: 'NoMoreForToday',
            params: {
              noReturn: true,
            },
          });
        }
      } else {
        this.isSubmitDisabled = false;
        console.error('La peticion ha fallado: ', result);
      }
    },
    continueModal(modal, nextModal) {
      this.closeModal(modal);
      this[nextModal] = true;
    },
    closeModal(modal) {
      this[modal] = false;
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
}

.content {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 85%;
}

.title {
  width: 105%;
  margin-top: 0px;
}

.div-text {
  font-family: workSans-medium;
  text-align: center;
  width: 80%;
  color: #aaa;
  font-size: 28px;
  margin-top: 20px;
  line-height: 0.9;
}

.div-text b {
  color: white;
}

.input-group {
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  width: 95%;
  margin-top: 15px;
}

.divInfograma {
  width: 90%;
  margin-top: 0px;
}

.scanImage {
  width: 100%;
  background-color: transparent;
}

.div-text2 {
  font-family: avenir;
  text-align: center;
  width: 90%;
  color: #aaa;
  line-height: 0.9;
  margin-top: 15px;
  font-size: 10px;
}

.input-group2 {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 10px;
}

.input-group2 input {
  padding: 15px 0px 15px 15px;
}

.divLine {
  display: inline-flex;
  padding: 10px 10px;
  width: 100%;
  position: relative;
  justify-content: center;
}

.inputCodes {
  border-radius: 2px;
  width: 223px;
  font-size: 12px;
}

.iInfo1,
.iInfo2 {
  background-color: #fff;
  position: relative;
  float: right;
  width: 20px;
  height: 20px;
  z-index: 999;
  right: 30px;
  top: 12px;
}

.tooltip2 {
  margin-top: 70px;
}

.tooltip1,
.tooltip2 {
  background-image: url('@/assets/images/Modals/BaseEncuesta.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  font-family: avenir;
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
  width: 200px;
  z-index: 1000;
  bottom: auto;
  left: auto;
  color: black;
}

.div-text3 {
  font-family: workSans-medium;
  text-align: center;
  width: 80%;
  color: #aaa;
  font-size: 10px;
  margin-top: 10px
}

.div-text3 b {
  color: white;
}

.bSend {
  width: 275px;
  cursor: pointer;
  margin: 20px 0px;
}

.bSend.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Modals */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1002;
}

.modal-content {
  font-family: spaceAge;
  display: flex;
  width: 100vw;
  height: auto;
  padding: 10%;
  background-image: url('@/assets/images/Modals/BaseEncuesta.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.modal-content-final {
  font-family: spaceAge;
  display: flex;
  width: 100vw;
  height: auto;
  padding: 10%;
  background-image: url('@/assets/images/Modals/BaseEncuestaUltima.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
}

.img-modal {
  display: flex;
  width: 60vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.img-modal button {
  width: 90px;
  margin: 5px;
  padding: 5px;
  font-family: spaceAge;
  border: 0.1px solid grey;
}

.btnAceptar {
  width: 110px !important;
  background-color: #740105;
  color: white;
}

.btnCerrar {
  background-color: #aaa;
  color: black;
}

.closeModal {
  width: 60px;
  cursor: pointer;
}

.continueModal {
  width: 75px;
  cursor: pointer;
}

@media (min-width: 390px) {
  .div-text {
    margin-top: 20px;
  }

  .input-group {
    margin-top: 20px;
  }

  .bSend {
    margin-top: 40px;
  }
}

@media (min-width: 435px) {
  .img-modal {
    width: 45vw;
  }
}

@media (min-width: 575px) {
  .img-modal {
    width: 35vw;
  }

  .modal-content {
    padding: 7%;
  }

  .modal-content-final {
    padding: 7%;
  }
}

@media (min-width: 640px) {
  .divInfograma {
    width: 40%;
  }
}

@media (min-width: 685px) {
  .img-modal {
    width: 25vw;
  }
}

@media (min-width: 767px) {
  .input-group {
    width: 55%;
  }

  .div-text {
    font-size: 32px;
  }

  .div-text2 {
    font-size: 12px;
  }

  .inputCodes {
    font-size: 14px;
  }

  .div-text3 {
    font-size: 12px;
  }

  .closeModal {
    width: 65px;
  }

  .continueModal {
    width: 75px;
  }

  .modal-content-final {
    padding: 6%;
  }
}

@media (min-width: 900px) {
  .modal-content {
    padding: 6%;
  }

  .modal-content-final {
    padding: 5%;
  }
}

@media (min-width: 960px) {
  .img-modal {
    width: 20vw;
  }
}

@media (min-width: 1100px) {
  .modal-content-final {
    padding: 4%;
  }
}

@media (min-width: 1500px) {
  .title {
    width: 100%;
  }

  .content {
    width: 55%;
  }

  .div-text {
    margin-top: 40px;
  }

  .input-group {
    margin-top: 40px;
  }

  .divInfograma {
    margin-top: 40px;
  }

  .div-text2 {
    font-size: 14px;
  }

  .div-text3 {
    font-size: 14px;
    margin-top: 40px;
  }

  .bSend {
    margin-top: 80px;
  }

  .img-modal {
    width: 15vw;
  }

  .modal-content {
    padding: 4%;
  }

  .modal-content-final {
    padding: 3%;
  }

  .closeModal {
    width: 70px;
  }

  .continueModal {
    width: 80px;
  }
}

@media (min-width: 2000px) {
  .img-modal {
    width: 10vw;
  }

  .modal-content {
    padding: 2%;
  }

  .modal-content-final {
    padding: 2%;
  }
}

@media (min-width: 3000px) {
  .img-modal {
    width: 7vw;
  }

  .modal-content-final {
    padding: 1.5%;
  }
}
</style>