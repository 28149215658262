<template>
  <MenuContent v-if="menuContentVisible" :isMenuContentVisible="true"></MenuContent>
  <div :ref="styleModalButtons" :class="'container' + styleModal" @scroll="checkScroll" @touchmove="checkScroll">
    <div :class="'div-text' + styleModal">
      <h2 :class="'title' + styleModal">{{ textTitle }}</h2>

      <div>
        <span style="text-align: center;">
          <h3>{{ $t('components.termsConditions.title1') }}</h3>
          <h3>{{ $t('components.termsConditions.title2') }}</h3>
        </span>
      </div>

      <div>
        <span>
          <p>
            {{ $t('components.termsConditions.text1.text1') }}
            <b style="color: black !important;"> {{ $t('components.termsConditions.text1.text2') }} </b>
            {{ $t('components.termsConditions.text1.text3') }}
          </p>
        </span>
      </div>

      <div>
        <span>
          <h4>{{ $t('components.termsConditions.paragraph1.title') }}</h4>
          <p>{{ $t('components.termsConditions.paragraph1.text1') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h4>{{ $t('components.termsConditions.paragraph2.title') }}</h4>
          <p>{{ $t('components.termsConditions.paragraph2.text1') }}</p>
          <p>{{ $t('components.termsConditions.paragraph2.text2') }}</p>
          <ol class="custom-list">
            <li><p>{{ $t('components.termsConditions.paragraph2.list1') }}</p></li>
            <li>
              <p>
              {{ $t('components.termsConditions.paragraph2.list2.text1') }}
              <a href='https://www.descubretuexperiencia.es' target="_blank"
                style="color: blue !important;">{{ $t('components.termsConditions.paragraph2.list2.text2') }}</a>
              {{ $t('components.termsConditions.paragraph2.list2.text3') }}
              </p>
            </li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list3') }}</p></li>
            <ol class="custom-list" style="list-style-type: lower-alpha;">
              <li>
                <p>          
                  {{ $t('components.termsConditions.paragraph2.list4.text1') }}
                  <a href="https://www.aladinia.com" target="_blank"
                    style="color: blue !important;">{{ $t('components.termsConditions.paragraph2.list4.text2') }}</a>
                </p>
              </li>
              <li><p>{{ $t('components.termsConditions.paragraph2.list5') }}</p></li>
              <ol class="custom-list" style="list-style-type: lower-roman;">
                <li><p>{{ $t('components.termsConditions.paragraph2.list6') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list7') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list8') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list9') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list10') }}</p></li>
              </ol>
              <li><p>{{ $t('components.termsConditions.paragraph2.list11') }}</p></li>
              <ol class="custom-list" style="list-style-type: lower-roman;">
                <li><p>{{ $t('components.termsConditions.paragraph2.list12') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list13') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list14') }}</p></li>
              </ol>
              <li><p>{{ $t('components.termsConditions.paragraph2.list15') }}</p></li>
              <ol class="custom-list" style="list-style-type: lower-roman;">
                <li><p>{{ $t('components.termsConditions.paragraph2.list16') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list17') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list18') }}</p></li>
                <li><p>{{ $t('components.termsConditions.paragraph2.list19') }}</p></li>
              </ol>
            </ol>
          </ol>

          <p>{{ $t('components.termsConditions.paragraph2.list20') }}</p>
          <p>{{ $t('components.termsConditions.paragraph2.list21') }}</p>

          <ol class="custom-list" start="4">
            <li><p>{{ $t('components.termsConditions.paragraph2.list22') }}</p></li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list23') }}</p></li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list24') }}</p></li>
            <li>
              <p>{{ $t('components.termsConditions.paragraph2.list25.text1') }}</p>
              <p>{{ $t('components.termsConditions.paragraph2.list25.text2') }}</p>  
              <p>{{ $t('components.termsConditions.paragraph2.list25.text3') }}</p>
            </li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list26') }}</p></li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list27') }}</p></li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list28') }}</p></li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list29') }}</p></li>
            <li><p>{{ $t('components.termsConditions.paragraph2.list30') }}</p></li>
          </ol>
        </span>
      </div>

      <div>
        <span>
          <h4>{{ $t('components.termsConditions.paragraph3.title') }}</h4>
          <p>{{ $t('components.termsConditions.paragraph3.text1') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h3>{{ $t('components.termsConditions.paragraph4.title') }}</h3>
          <p>{{ $t('components.termsConditions.paragraph4.text1') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h3>{{ $t('components.termsConditions.paragraph5.title') }}</h3>
          <p>{{ $t('components.termsConditions.paragraph5.text1') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h3>{{ $t('components.termsConditions.paragraph6.title') }}</h3>
          <p>{{ $t('components.termsConditions.paragraph6.text1') }}</p>
          <p>{{ $t('components.termsConditions.paragraph6.text2') }}</p>
          <p>{{ $t('components.termsConditions.paragraph6.text3') }}</p>
          <p>{{ $t('components.termsConditions.paragraph6.text4') }}</p>
          <p>{{ $t('components.termsConditions.paragraph6.text5') }}</p>
          <p>{{ $t('components.termsConditions.paragraph6.text6') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h3>{{ $t('components.termsConditions.paragraph7.title') }}</h3>
          <p>{{ $t('components.termsConditions.paragraph7.text1') }}</p>
          <p>{{ $t('components.termsConditions.paragraph7.text2') }}</p>
          <p>{{ $t('components.termsConditions.paragraph7.text3') }}</p>
          <p>{{ $t('components.termsConditions.paragraph7.text4') }}</p>
          <p>{{ $t('components.termsConditions.paragraph7.text5') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h3>{{ $t('components.termsConditions.paragraph8.title') }}</h3>
          <p>{{ $t('components.termsConditions.paragraph8.text1') }}</p>
        </span>
      </div>

      <div>
        <span>
          <h3>{{ $t('components.termsConditions.paragraph9.title') }}</h3>
          <p>{{ $t('components.termsConditions.paragraph9.text1') }}</p>
        </span>
      </div>

      <div>
        <span>
          <p>
            {{ $t('components.termsConditions.text2.text1') }}
            <b style="color: black !important;">{{ $t('components.termsConditions.text2.text2') }}</b>
            {{ $t('components.termsConditions.text2.text3') }}
          </p>
        </span>
      </div>

      <div>
        <span style="text-align: center;">
          <p>
            <img :src="sign" alt="sign">
            <br>
            <b style="color: black !important;">{{ $t('components.termsConditions.text3.text1') }}</b>
            <br>
            {{ $t('components.termsConditions.text3.text2') }}
          </p>
        </span>
      </div>

    </div>
  </div>
</template>
<script>
import MenuContent from "@/components/MenuContent.vue";

export default {
  name: 'TermsConditions',
  props: {
    menuContentVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    styleModal: {
      type: String,
      required: false,
      default: ''
    },
    styleModalButtons: {
      type: String,
      required: false,
      default: ''
    },
    titleModal: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    MenuContent
  },
  data() {

    return {
      textTitle: this.titleModal != '' ? this.titleModal : this.$t('components.termsConditions.title'),
      sign: require('@/assets/images/Legal/FirmaBL.png'),
    }
  },
  methods: {
    checkScroll() {
      const content = this.$refs.modalButtons;

      if (content){
        const scrollFromTop = content.scrollTop;
        const visibleHeight = content.clientHeight;
        const totalHeight = content.scrollHeight;
  
  
        if (scrollFromTop + visibleHeight >= totalHeight - 10) {
          this.$emit('scrolled-to-bottom');
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
  font-family: avenir;
}

.div-text {
  text-align: justify;
  width: 85%;
  color: rgb(0, 0, 0);
  font-size: 12px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-image: url('@/assets/images/Legal/CapaLegales.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  box-sizing: content-box;
}

.div-text b {
  color: white;
}

.title {
  text-align: center;
  font-family: spaceAge;
  font-size: 15px;
  color: white;
  padding: 6%;
  background-image: url('@/assets/images/Legal/TituloLegales.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

/* MODAL */
.container-modal {
  display: flex;
  height: auto;
  overflow-y: auto;
  font-family: avenir;
  width: 65vw;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.div-text-modal {
  display: flex;
  text-align: justify;
  color: rgb(0, 0, 0);
  font-size: 12px;
  height: auto;
  width: 65vw;
  flex-direction: column;
}

.div-text-modal .custom-list {
  margin-left: 0.75rem;
  padding-left: 0.75rem;
}

.div-text-modal b {
  color: white;
}

.title-modal {
  font-family: spaceAge;
  font-size: 20px;
  color: white;
  padding: 3%;
  background-image: url('@/assets/images/Legal/TituloLegales.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
}

.img-modal button {
  width: 90px;
  margin: 5px;
  padding: 5px;
  font-family: spaceAge;
}

.btnAceptar {
  background-color: #740105;
  color: white;
  border: 0.1px solid grey;
}

@media (min-width: 380px) {
  .container-modal {
    width: 62vw;
  }

  .div-text-modal {
    width: 62vw;
  }
}

@media (min-width: 400px) {
  .container-modal {
    width: 60vw;
  }

  .div-text-modal {
    width: 60vw;
  }
}

@media (min-width: 435px) {
  .container-modal {
    width: 55vw;
  }

  .div-text-modal {
    width: 55vw;
  }
}

@media (min-width: 450px) {
  .div-text {
    width: 70%;
  }
}

@media (min-width: 500px) {
  .container-modal {
    width: 50vw;
  }

  .div-text-modal {
    width: 50vw;
  }
}

@media (min-width: 575px) {
  .container-modal {
    width: 45vw;
  }

  .div-text-modal {
    width: 45vw;
  }
}

@media (min-width: 630px) {
  .container-modal {
    width: 40vw;
  }

  .div-text-modal {
    width: 40vw;
  }
}

@media (min-width: 685px) {
  .container-modal {
    width: 35vw;
  }

  .div-text-modal {
    width: 35vw;
  }
}

@media (min-width: 767px) {
  .div-text {
    width: 50%;
    font-size: 14px;
  }

  .title {
    font-size: 17px;
  }

  .div-text-modal {
    font-size: 14px;
  }

  .title-modal {
    font-size: 17px;
  }
}

@media (min-width: 960px) {
  .container-modal {
    width: 25vw;
  }

  .div-text-modal {
    width: 25vw;
  }
}

@media (min-width: 1000px) {
  .title {
    padding: 5%;
  }
}

@media (min-width: 1500px) {
  .div-text {
    font-size: 16px;
  }

  .title {
    font-size: 19px;
  }

  .div-text {
    width: 30%;
    padding: 2%;
  }

  .div-text-modal {
    font-size: 16px;
  }

  .div-text-modal .custom-list {
    margin-left: 1rem;
    padding-left: 1rem;
  }

  .title-modal {
    font-size: 19px;
  }
}

@media (min-width: 1696px) {
  .container-modal {
    width: 17vw;
  }

  .div-text-modal {
    width: 15vw;
  }
}

@media (min-width: 2000px) {
  .title {
    padding: 3%;
  }

  .container-modal {
    width: 18vw;
  }

  .div-text-modal {
    width: 18vw;
  }
}

@media (min-width: 2500px) {
  .container-modal {
    width: 18vw;
  }

  .div-text-modal {
    width: 18vw;
  }
}
</style>