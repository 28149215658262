<template>
  <MenuContent :isMenuContentVisible="true"></MenuContent>
  <div class="home">

    <img class="title" :src="titulo" alt="titulo">

    <div class="div-text">
      <span>
        <b>{{ $t('components.faq.title') }}</b>
      </span>
    </div>

    <div class="content">
      <div class="title-content">{{ $t('components.faq.ambito.title') }}</div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q1viewAsk1')"><b>{{ $t('components.faq.ambito.ask1.title')
            }}</b></button>
        <div class="span-content" v-show="q1viewAsk1">{{ $t('components.faq.ambito.ask1.text') }}</div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q1viewAsk2')">
          <b>{{ $t('components.faq.ambito.ask2.title') }}</b>
        </button>
        <div class="span-content" v-show="q1viewAsk2">
          {{ $t('components.faq.ambito.ask2.text') }}
          {{ $t('components.faq.ambito.ask2.text3') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q1viewAsk3')">
          <b>{{ $t('components.faq.ambito.ask3.title') }}</b>
        </button>
        <div class="span-content" v-show="q1viewAsk3">{{ $t('components.faq.ambito.ask3.text') }}</div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q1viewAsk4')">
          <b>{{ $t('components.faq.ambito.ask4.title') }}</b>
        </button>
        <div class="span-content" v-show="q1viewAsk4">
          {{ $t('components.faq.ambito.ask4.text') }}
          <router-link to="/terms_Conditions" style="color: blue !important;">{{ $t('components.faq.ambito.ask4.text2')}}</router-link>
          {{ $t('components.faq.ambito.ask4.text3') }} 
        </div>
      </div>
    </div>

    <div class="content2">
      <div class="title-content">{{ $t('components.faq.stake.title') }}</div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q2viewAsk1')">
          <b>{{ $t('components.faq.stake.ask1.title') }}</b>
        </button>
        <div class="span-content" v-show="q2viewAsk1">
          {{ $t('components.faq.stake.ask1.text1') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q2viewAsk2')">
          <b>{{ $t('components.faq.stake.ask2.title') }}</b>
        </button>
        <div class="span-content" v-show="q2viewAsk2">
          {{ $t('components.faq.stake.ask2.text1') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q2viewAsk3')">
          <b>{{ $t('components.faq.stake.ask3.title') }}</b>
        </button>
        <div class="span-content" v-show="q2viewAsk3">
          {{ $t('components.faq.stake.ask3.text1') }}
        </div>
      </div>
    </div>

    <div class="content3">
      <div class="title-content">{{ $t('components.faq.awards.title') }}</div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk1')">
          <b>{{ $t('components.faq.awards.ask1.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk1">
          {{ $t('components.faq.awards.ask1.text1') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk2')">
          <b>{{ $t('components.faq.awards.ask2.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk2">
          {{ $t('components.faq.awards.ask2.text1') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk3')">
          <b>{{ $t('components.faq.awards.ask3.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk3">
          {{ $t('components.faq.awards.ask3.text1') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk4')">
          <b>{{ $t('components.faq.awards.ask4.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk4">
          {{ $t('components.faq.awards.ask4.text1') }}
          <br>
          {{ $t('components.faq.awards.ask4.text2') }}
        </div>
      </div>
      
      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk5')">
          <b>{{ $t('components.faq.awards.ask5.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk5">
          {{ $t('components.faq.awards.ask5.text1') }}
        </div>
      </div>

      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk6')">
          <b>{{ $t('components.faq.awards.ask6.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk6">
          {{ $t('components.faq.awards.ask6.text1') }}
          <a href="https://www.aladinia.com" target="_blank" style="color: blue !important;">{{ $t('components.faq.awards.ask6.text2') }}</a>
        </div>
      </div>
      
      <div class="ask">
        <button class="button-ask" @click="openAsk('q3viewAsk7')">
          <b>{{ $t('components.faq.awards.ask7.title') }}</b>
        </button>
        <div class="span-content" v-show="q3viewAsk7">
          {{ $t('components.faq.awards.ask7.text1') }}
          <br>
          {{ $t('components.faq.awards.ask7.text2') }}
          <ol>
            <li>
              {{ $t('components.faq.awards.ask7.text3.text1') }}
              <b style="color: black !important;">{{ $t('components.faq.awards.ask7.text3.text2') }}</b>
              {{ $t('components.faq.awards.ask7.text3.text3') }}
            </li>
            <li>
              {{ $t('components.faq.awards.ask7.text4.text1') }}
              <b>{{ $t('components.faq.awards.ask7.text4.text2') }} </b>
            </li>
            <li>
              {{ $t('components.faq.awards.ask7.text5.text1') }}
              <b>{{ $t('components.faq.awards.ask7.text5.text2') }}</b>
            </li>
          </ol>
          
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import MenuContent from "@/components/MenuContent.vue";

export default {
  name: 'FaQ',
  components: {
    MenuContent
  },
  data() {
    return {
      titulo: require('@/assets/images/Titulo.png'),
      q1viewAsk1: false,
      q1viewAsk2: false,
      q1viewAsk3: false,
      q1viewAsk4: false,

      q2viewAsk1: false,
      q2viewAsk2: false,
      q2viewAsk3: false,

      q3viewAsk1: false,
      q3viewAsk2: false,
      q3viewAsk3: false,
      q3viewAsk4: false,
      q3viewAsk5: false,
      q3viewAsk6: false,
      q3viewAsk7: false,
    };
  },
  methods: {
    openAsk(view) {
      this[view] = !this[view];
    }
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
}

.title {
  width: 90%;
  margin-top: 10px;
}

.div-text {
  font-family: workSans-medium;
  text-align: center;
  width: 70%;
  color: #aaa;
  font-size: 30px;
  margin-top: 20px;


}
.div-text b {
    color: white;
  }

.content {
  display: flex;
  flex-flow: column;
  width: 80%;
  margin-top: 20px;
  align-items: center;
}

.content2 {
  display: flex;
  flex-flow: column;
  width: 80%;
  margin-top: 20px;
  align-items: center;
}

.content3 {
  display: flex;
  flex-flow: column;
  width: 80%;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.title-content {
  font-family: workSans-bold;
  font-size: 16px;
  width: 89%;
  text-align: center;
  padding: 4.5%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: black;
  background-color: #ffb300;
  clip-path: polygon(0 0, 95% 0, 100% 20px, 100% 100%, 0% 100%);
  margin-bottom: 5px;
}

.ask {
  font-family: avenir;
  display: flex;
  flex-direction: column;
  width: 98%;
}

.button-ask {
  font-family: avenir;
  padding: 4%;
  padding-right: 8%; 
  font-size: 12px;
  text-align: left;
  background-image: url('@/assets/images/Legal/IconoBoton.png');
  background-repeat: no-repeat;
  background-size: 1em auto;
  background-position: 95% center;
  border: solid #aaa 1px;
}

.button-ask b {
    color: black !important;
}

.span-content {
  font-size: 12px;
  background-color: white !important;
  padding: 3%;
  color: black;
  margin-left: 2px;
  margin-right: 2px;
}

@media (min-width: 650px) {
  .home {
    width: 70%;
  }
}

@media (min-width: 1050px) {
  .home {
    width: 60%;
  }

}

@media (min-width: 1696px) {
  .home {
    width: 30%;
  }
}

@media (min-width: 767px) {

  .title-content {
    font-size: 18px;
  }

  .button-ask {
    font-size: 16px;
  }

  .span-content {
    font-size: 14px;
  }

}

@media (min-width: 1500px) {
  .title {
    width: 100%;
  }

  .title-content {
    font-size: 20px;
  }

  .button-ask {
    font-size: 18px;
  }

  .span-content {
    font-size: 16px;
  }

  .div-text {
    margin-top: 50px;
  }

  .content {
    margin-top: 50px;
  }

  .content2 {
    margin-top: 50px;
  }

  .content3 {
    margin-top: 50px;
  }
}
</style>