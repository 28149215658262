<template>
  <div class="home">
    <div class="div-text">
      <div class="span1">
        <span>
          <b>{{ $t('ageGate.title') }}</b>
        </span>
      </div>
      <div class="span2">
        <span>
          <b>{{ $t('ageGate.text.text1') }}<br>
            {{ $t('ageGate.text.text2') }}<br>
            {{ $t('ageGate.text.text3') }}<br>
            {{ $t('ageGate.text.text4') }}</b>
        </span>
      </div>
    </div>

    <div class="div-select">
      <select v-model="selectedMonth" class="contentSelect" :class="{ 'placeholder-color': selectedMonth === ''}" >
        <option value="" disabled>{{ $t('ageGate.month') }} </option>
        <option v-for="month in months" :key="month.value" :value="month.value">
          {{ month.text }}
        </option>
      </select>

      <select v-model="selectedYear" :class="{ 'placeholder-color': selectedYear === '' }">
        <option value="" disabled>{{ $t('ageGate.year') }}</option>
        <option v-for="year in years" :key="year" :value="year">
          {{ year }}
        </option>
      </select>
    </div>

    <img class="confirm" :src="confirmBtn" alt="confirmBtn" @click="navigateToLandingAccess">


    <!--Modal error-->
    <div v-if="showError" class="modal">
      <div class="modal-content-final">
        <ModalWarning :textModal="textError"></ModalWarning>
        <div class="img-modal">
          <button class="btnAceptar" @click="onCloseError">CERRAR</button>
        </div>
      </div>
    </div>

  </div>

  <span class="span-text">
    <b>{{ $t('generic.information.text1') }}</b>
    {{ $t('generic.information.text2') }}
    <b>{{ $t('generic.information.text3') }}</b>
    {{ $t('generic.information.text4') }}
    <b>
      <a href='https://pmiprivacy.com/es/consumer' target="_blank" style="color: white !important;">
        {{ $t('generic.information.text5') }}
      </a>
    </b>
  </span>

</template>


<script>
import { useInfoStore } from '@/stores/useInfoStore';
import ModalWarning from "@/components/Modals/ModalWarning.vue";

export default {
  components: {
    ModalWarning
  },
  name: 'AgeGateView',
  data() {
    const infoStore = useInfoStore();

    return {
      confirmBtn: require('@/assets/images/Confirmar.png'),
      close: require('@/assets/images/Modals/ModalCerrar.png'),
      infoStore: infoStore,
      selectedMonth: '',
      selectedYear: '',
      months: [
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      years: [],
      showError: false,
      textError: ''
    };
  },
  mounted() {
    this.preloadImage(require('@/assets/images/Modals/BaseEncuestaUltima.png'));
  },
  created() {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1900; year--) {
      this.years.push(year);
    }
  },
  methods: {
    preloadImage(imagePath) {
      const img = new Image();
      img.src = imagePath; // Precarga de la imagen con la URL correcta generada
    },
    calculateAge() {
      if (!this.selectedMonth || !this.selectedYear) {
        return -1; // No se ha seleccionado mes o año, edad no calculable
      }

      /* TODO: Aqui hay ejemplo de generar una fecha con el año y el mes seleccionado */
      const birthDate = new Date(`${this.selectedYear}-${this.selectedMonth}-01`);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },

    navigateToLandingAccess() {
      const age = this.calculateAge();

      if (age >= 18) {
        this.infoStore.setValue(this.selectedMonth, 'month');
        this.infoStore.setValue(this.selectedYear, 'year');
        this.$router.push({
          name: 'LandingAccess',
        });

      } else if (age === -1) {
        this.showError = true;
        this.textError = this.$t('ageGate.modalErrors.text2');
        return;

      } else {
        this.showError = true;
        this.textError = this.$t('ageGate.modalErrors.text1');
        return;
      }
    },

    onCloseError() {
      this.showError = false
    },

  },
};

</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: auto;
}


.div-text {
  font-family: workSans-medium;
  text-align: center;
  width: 90%;
  color: #aaa;
  margin-top: 70px;
  line-height: 0.9;
}

.div-text b {
  color: white;
}

.span1 {
  font-size: 40px;
}

.span2 {
  font-size: 20px;
  margin-top: 20px;
}

.div-select {
  /* display: flex;
  flex-direction: row; */
  width: 100%;
  text-align: center;
  /* justify-content: space-between; */
  margin-top: 45px;
}

select {
  width: 45%;
  font-size: 14px;
  font-family: spaceAge;
  padding: 2%;
  appearance: none;
  background-image: url('@/assets/images/FlechasSelect.png');
  background-repeat: no-repeat;
  background-size: 0.8em auto;
  background-position: 95% center;
}

.contentSelect{
  margin-right: 5px;
}

.placeholder-color {
  color: red;
  font-size: 18px;
}

.confirm {
  width: 250px;
  margin-top: 75px;
}

.span-text {
  font-family: avenir;
  font-size: 10px;
  text-align: justify;
  width: 70%;
  color: #aaa;
  margin-top: 30px;
}

.span-text b {
  color: white;
}



/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1002;
}

.modal-content-final {
  font-family: spaceAge;
  display: flex;
  width: 100vw;
  /* max-height: 50vh; */
  height: auto;
  padding: 10%;
  background-image: url('@/assets/images/Modals/BaseEncuestaUltima.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
}

.img-modal {
  display: flex;
  width: 55vw;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.img-modal button {
  width: 90px;
  margin: 5px;
  padding: 5px;
}

.closeModal {
  width: 60px;
  cursor: pointer;
}

.img-modal button {
  width: 90px;
  margin: 5px;
  padding: 5px;
  font-family: spaceAge;
}

.btnAceptar {
  background-color: #740105;
  color: white;
  border: 0.1px solid grey;
}

@media (min-width: 390px) {

  .span2 {
    margin-top: 50px;
  }

  .div-select {
    margin-top: 60px;
  }

  .confirm {
    margin-top: 80px;
  }

  .span-text {
    margin-top: 80px;
  }

}

@media (min-width: 435px) {
  .img-modal {
    width: 45vw;
  }

}


@media (min-width: 767px) {

  .div-select {
    width: 70%;
  }

  .placeholder-color {
    font-size: 20px;
  }

  select {
    font-size: 20px;
  }

  .span-text {
    font-size: 13px;
    width: 50%;
  }

}

@media (min-width: 575px) {
  .img-modal {
    width: 35vw;
  }

  .modal-content-final {
    padding: 7%;
  }
}

@media (min-width: 685px) {
  .img-modal {
    width: 25vw;
  }
}

@media (min-width: 767px) {
  .closeModal {
    width: 65px;
  }

  .modal-content-final {
    padding: 6%;
  }
}

@media (min-width: 900px) {
  .modal-content-final {
    padding: 5%;
  }
}

@media (min-width: 960px) {
  .img-modal {
    width: 20vw;
  }
}

@media (min-width: 1100px) {
  .modal-content-final {
    padding: 4%;
  }
}

@media (min-width: 1500px) {
  .span1 {
    width: 100%;
    font-size: 55px;
  }

  .span2 {
    font-size: 40px;
    margin-top: 130px;
  }

  .div-select {
    margin-top: 140px;
  }

  .placeholder-color {
    font-size: 22px;
  }

  select {
    font-size: 22px;
  }

  .confirm {
    margin-top: 150px;
  }

  .span-text {
    font-size: 15px;
  }

  .span-text {
    margin-top: 150px;
  }


  .img-modal {
    width: 15vw;
  }

  .modal-content-final {
    padding: 3%;
  }

  .closeModal {
    width: 70px;
  }

}

@media (min-width: 2000px) {
  .img-modal {
    width: 10vw;
  }

  .modal-content-final {
    padding: 2%;
  }
}

@media (min-width: 3000px) {
  .img-modal {
    width: 7vw;
  }

  .modal-content-final {
    padding: 1.5%;
  }

}
</style>
