<template>
    <MenuContent></MenuContent>
    <div class="home">

        <img class="title" :src="titulo" alt="titulo">

        <div class="div-text">
            <span>
                <b>{{ $t('confirmPrize.title') }}</b>
            </span>
        </div>

        <div class="div-text2">
            <span>
                <b>{{ $t('confirmPrize.generalText.text1') }}</b>
            </span>
        </div>

        <div class="div-text3">
            <span>
                <b>{{ $t('confirmPrize.jackpot') }}</b>
            </span>
        </div>

        <div class="div-text4">
            <span>
                <b>{{ $t('confirmPrize.generalText.text2') }}</b>
            </span>
        </div>

        <img @click="againParticipation" class="participation" :src="keepParticipating" alt="keepParticipating">

    </div>

    <!-- Modal errors -->
    <div v-if="showError" class="modal">
        <div class="modal-content-final">
            <ModalWarning :textModal="textError"></ModalWarning>
            <div class="img-modal">
                <button class="btnAceptar" @click="closeModal('showError')">CERRAR</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useInfoStore } from '@/stores/useInfoStore';
import { useRequestStore } from "@/stores/useRequestsStore";
import MenuContent from "@/components/MenuContent.vue";
import ModalWarning from "@/components/Modals/ModalWarning.vue";

export default {
    name: 'JackpotRegView',
    components: {
        MenuContent,
        ModalWarning
    },
    data() {
        const infoStore = useInfoStore();
        const requestStore = useRequestStore();

        return {
            titulo: require('@/assets/images/Titulo.png'),
            iSorry: require('@/assets/images/ScreensSorry/ImagenPerdon.png'),
            keepParticipating: require('@/assets/images/SeguirParticipando.png'),
            infoStore: infoStore,
            requestStore: requestStore,
            showError: false,
            textError: '',
        }
    },
    mounted() {
        if (localStorage.getItem('canLoad') != "2"){
            this.$router.push({
                name: 'LandingAccess'
            });
        }
    },
    beforeUnmount(){
        localStorage.removeItem('canLoad');
    },
    methods: {
        async againParticipation() {
            this.$router.push({
                name: 'LandingScan'
            });
        }
    }
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    font-family: workSans-medium;
}

.title {
    width: 90%;
    margin-top: 10px;
}

b {
    color: white;
}

.div-text {
    text-align: center;
    width: 90%;
    color: #aaa;
    font-size: 30px;
    margin-top: 60px;
}

.div-text b {
    color: orange;
}

.div-text2 {
    text-align: center;
    width: 60%;
    color: #aaa;
    font-size: 17px;
    margin-top: 15px;
}

/* Segun la pantalla ajustar el width, font-size, padding y tranform */
.div-text3 {
    font-family: avenir;
    text-align: center;
    width: 85%;
    color: #aaa;
    font-size: 11px;
    padding: 6%;
    margin-top: 20px;
    background-image: url('@/assets/images/ConfirmPrizeView/CuadroTexto.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    box-sizing: border-box;
}

.div-text4 {
    text-align: center;
    width: 70%;
    color: #aaa;
    font-size: 17px;
    margin-top: 20px;
}

.div-text4 b {
    color: orange;
}

.participation {
    width: 250px;
    cursor: pointer;
    margin-top: 50px;
}

/* Modals */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    z-index: 1002;
}

.modal-content-final {
    font-family: spaceAge;
    display: flex;
    width: 100vw;
    height: auto;
    padding: 10%;
    background-image: url('@/assets/images/Modals/BaseEncuestaUltima.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-sizing: content-box;
    flex-direction: column;
    align-items: center;
}

.img-modal {
    display: flex;
    width: 55vw;
    align-items: flex-end;
}

.img-modal button {
    width: 90px;
    margin: 5px;
    padding: 5px;
    font-family: spaceAge;
    border: 0.1px solid grey;
}

.btnAceptar {
    background-color: #740105;
    color: white;
}

@media (min-width: 380px) {
    .div-text3 {
        width: 85%;
        font-size: 15px;
        padding: 5%;
    }
}

@media (min-width: 390px) {

    .div-text {
        margin-top: 40px;
    }

    .div-text2 {
        margin-top: 20px;
    }

    .div-text3 {
        margin-top: 40px;
    }

    .div-text4 {
        margin-top: 40px;
    }

    .participation {
        margin-top: 70px;
    }

}

@media (min-width: 435px) {
    .img-modal {
        width: 45vw;
    }
}

@media (min-width: 499px) {
    .div-text3 {
        width: 80%;
        font-size: 15px;
    }
}

@media (min-width: 575px) {
    .img-modal {
        width: 35vw;
    }
}

@media (min-width: 578px) {
    .div-text3 {
        width: 70%;
    }
}

@media (min-width: 674px) {
    .div-text3 {
        width: 60%;
    }
}

@media (min-width: 685px) {
    .img-modal {
        width: 25vw;
    }
}

@media (min-width: 767px) {
    .div-text {
        font-size: 37px;
    }

    .div-text2 {
        font-size: 19px;
    }

    .div-text3 {
        font-size: 14px;
        width: 55%;
    }

    .div-text4 {
        font-size: 19px;
    }

    .modal-content-final {
        padding: 6%;
    }
}

@media (min-width: 809px) {
    .div-text3 {
        width: 50%;
    }
}

@media (min-width: 900px) {
    .modal-content-final {
        padding: 5%;
    }
}

@media (min-width: 960px) {
    .img-modal {
        width: 20vw;
    }
}

@media (min-width: 1100px) {
    .modal-content-final {
        padding: 4%;
    }
}

@media (min-width: 1500px) {
    .title {
        width: 100%;
    }

    .div-text {
        margin-top: 70px;
        font-size: 39px;
    }

    .div-text2 {
        margin-top: 40px;
        font-size: 21px;
    }

    .div-text3 {
        margin-top: 60px;
        font-size: 17px;
    }

    .div-text4 {
        margin-top: 50px;
        font-size: 21px;
    }

    .participation {
        margin-top: 100px;
    }

    .img-modal {
        width: 15vw;
    }

    .modal-content-final {
        padding: 3%;
    }
}

@media (min-width: 2000px) {
    .img-modal {
        width: 10vw;
    }

    .modal-content-final {
        padding: 2%;
    }
}

@media (min-width: 3000px) {
    .img-modal {
        width: 7vw;
    }

    .modal-content-final {
        padding: 1.5%;
    }
}
</style>