<template>
    <MenuContent></MenuContent>
    <div class="home">
        <img class="title" :src="titulo" alt="titulo">
        <div class="div-text">
            <span>
                <b>{{ $t('winner.title1') }}
                    <br>
                    {{ $t('winner.title2') }}
                </b>
            </span>
        </div>
        <img class="image" :src="iRally" alt="iRally">

        <div class="div-text2">
            <span>
                <b>{{ $t('winner.rally.text1') }}</b>
            </span>
        </div>
        <div class="div-text3">
            <span>
                <b>{{ $t('winner.rally.text2') }}</b>
            </span>
        </div>
        <div class="div-text4">
            <span>
                {{ $t('winner.generalText.text1') }}
                <br>
                <b class="mobileNum">{{ mobile }}</b>
                <br>
                {{ $t('winner.generalText.text2') }}
            </span>
        </div>
        <div class="div-text5">
            <span>
                {{ $t('winner.generalText.text3') }}
            </span>
        </div>
        <img @click="againParticipation" class="participation" :src="keepParticipating" alt="keepParticipating">
    </div>
</template>

<script>
import MenuContent from "@/components/MenuContent.vue";
import { useInfoStore } from '@/stores/useInfoStore';
import { storeToRefs } from 'pinia';

export default {
    name: 'PrizeRallyView',
    components: {
        MenuContent,
    },
    //Recordar que el campo de movile se recogeria desde la store
    data() {
        const infoStore = useInfoStore();
        const { mobile } = storeToRefs(infoStore);

        return {
            titulo: require('@/assets/images/Titulo.png'),
            iRally: require('@/assets/images/PrizeScreens/PremioRally.png'),
            keepParticipating: require('@/assets/images/SeguirParticipando.png'),
            mobile: mobile,
        }
    },
    mounted() {
        if (localStorage.getItem('canLoad') != "1"){
            this.$router.push({
                name: 'LandingAccess'
            });
        }
    },
    beforeUnmount(){
        localStorage.removeItem('canLoad');
    },
    methods: {
        againParticipation() {
            const infoStore = useInfoStore();

            infoStore.resetData();

            this.$router.push({
                name: 'LandingScan'
            });
        }
    }
};
</script>

<style scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    font-family: workSans-medium;
}

.title {
    width: 90%;
    margin-top: 10px;
}

b {
    color: white;
}

.div-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70%;
    color: #aaa;
    font-size: 25px;
    margin-top: 20px;
}

.image {
    width: 100px;
    height: 100px;
    margin-top: 15px;
}

.div-text2 {
    text-align: center;
    width: 70%;
    color: #aaa;
    font-size: 25px;
    margin-top: 10px;
}

.div-text3 {
    text-align: center;
    width: 90%;
    color: #aaa;
    font-size: 25px;
    margin-top: 15px;
}

.div-text3 b {
    color: orange;
}

.div-text4 {
    text-align: center;
    width: 70%;
    font-size: 12px;
    margin-top: 40px;
}

.div-text5 {
    text-align: center;
    width: 80%;
    font-size: 12px;
    margin-top: 10px;
}

.mobileNum {
    font-size: 20px;
}

.participation {
    width: 250px;
    cursor: pointer;
    margin-top: 30px;
}

@media (min-width: 390px) {
    .div-text {
        margin-top: 20px;
    }

    .div-text2 {
        margin-top: 10px;
    }

    .div-text3 {
        margin-top: 10px;
    }

    .div-text4 {
        margin-top: 10px;
    }

    .div-text5 {
        margin-top: 20px;
    }

    .participation {
        margin-top: 20px;
    }
}

@media (min-width: 767px) {
    .div-text {
        font-size: 37px;
    }

    .div-text2 {
        font-size: 37px;
    }

    .div-text3 {
        font-size: 37px;
    }

    .div-text4 {
        font-size: 14px;
    }

    .div-text5 {
        font-size: 14px;
    }
}

@media (min-width: 1500px) {
    .title {
        width: 100%;
    }

    .div-text {
        margin-top: 50px;
        font-size: 39px;
    }

    .div-text2 {
        margin-top: 50px;
        font-size: 39px;
    }

    .div-text3 {
        margin-top: 50px;
        font-size: 39px;
    }

    .div-text4 {
        margin-top: 50px;
        font-size: 16px;
    }

    .div-text5 {
        margin-top: 50px;
        font-size: 16px;
    }

    .participation {
        margin-top: 50px;
    }
    .mobileNum {
        font-size: 24px;
    }
}
</style>